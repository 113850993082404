import { Button } from '@/components';
import { OSCAR_OPENID_URL } from '@/const';
import I18N from '@/utils/I18N';
import classNames from 'classnames';
import styles from './index.less';

interface Props {
	isMobile: boolean;
}
export const SignInWithOpenid = (props: Props) => {
	const { isMobile } = props;
	// if (isMobile) {
	// 	return null;
	// }
	const url = sessionStorage.getItem(OSCAR_OPENID_URL);
	if (!url) {
		return null;
	}
	return (
		<div className={classNames('w-full flex flex-col', styles.openidWrap)}>
			<div className={classNames('flex items-center', styles.or)}>
				{I18N.auto.nonOverseasEmployees}
			</div>
			<Button
				type="checked-neutral"
				onClick={() => {
					location.href = url;
				}}
			>
				<div className="flex items-center justify-center">
					<img width={20} height={20} src={require('@/assets/images/openid-logo.png')} alt="" />
					<span className="ml-[8px] inline-block">{I18N.auto.signInWithOpenid}</span>
				</div>
			</Button>
		</div>
	);
};

export default SignInWithOpenid;
