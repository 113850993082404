export { default as getIsMobile } from './getIsMobile';

export function download(url: string, fileName = '') {
	let a = document.createElement('a');
	a.download = fileName || '';
	a.href = url;
	a.click();
}

//打开app，附带检测app是否安装
export const openApp = function (openUrl: string, callback: (isOpen: boolean) => any) {
	let startTime = Date.now();

	//检查app是否打开
	function checkOpen() {
		//启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
		let timer = -1;
		timer = window.setInterval(function () {
			let duration = Date.now() - startTime;
			if (document.hidden) {
				callback(true);
				clearInterval(timer);
			} else if (duration > 3000) {
				clearInterval(timer);
				callback(false);
			}
		}, 50);
	}

	//在iframe 中打开APP
	checkOpen();

	location.href = openUrl;
	// let ifr = document.createElement('iframe');
	// ifr.src = openUrl;
	// ifr.style.display = 'none';

	// document.body.appendChild(ifr);
	// setTimeout(function () {
	// 	document.body.removeChild(ifr);
	// }, 2000);
};
