// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box___TODjb {\n  width: 19rem;\n  min-height: 10rem;\n  background: #ffffff;\n  box-shadow: 0rem 0.4rem 3rem 0.8rem rgba(142, 153, 173, 0.04);\n  border-radius: 0.4rem;\n  box-sizing: border-box;\n  max-width: 100%;\n  padding: 1rem;\n}\n@media only screen and (max-width: 460px) {\n  .isPc___S5lyp.box___TODjb {\n    background-color: white;\n    width: 100%;\n    box-shadow: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/pageBox/pc.less"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,6DAAA;EACA,qBAAA;EACA,sBAAA;EACA,eAAA;EACA,aAAA;AACD;AACA;EAEC;IACC,uBAAA;IACA,WAAA;IACA,gBAAA;EAAA;AACF","sourcesContent":[".box {\n\twidth: 380px;\n\tmin-height: 200px;\n\tbackground: #ffffff;\n\tbox-shadow: 0px 8px 60px 16px rgba(142, 153, 173, 0.04);\n\tborder-radius: 8px;\n\tbox-sizing: border-box;\n\tmax-width: 100%;\n\tpadding: 20px;\n}\n@media only screen and (max-width: 460px) {\n\t//pc窄屏适配\n\t.isPc.box {\n\t\tbackground-color: white;\n\t\twidth: 100%;\n\t\tbox-shadow: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "box___TODjb",
	"isPc": "isPc___S5lyp"
};
export default ___CSS_LOADER_EXPORT___;
