import { HelpBtn, LocaleSetting } from '@/components';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './index.less';

interface Props {}

export const ActionBar: FC<Props> = () => {
	return (
		<div className={classNames('flex justify-between items-center', styles.actionBar)}>
			<div className="left">
				<LocaleSetting />
			</div>
			<div className="right">
				<HelpBtn />
			</div>
		</div>
	);
};

export default ActionBar;
