import IframeModal from '@/components/iframeModal';
import { openInnerWebview } from '@/utils/bridge';

import I18N, { getCurrentLang } from '@/utils/I18N';
import device from './device';
import { openUrlWithFrom, safeOpenUrl } from '@/utils/openUrl';

export default function openHelpCenter() {
	const path = device.isMobile ? 'h5' : 'web';

	let locale = getCurrentLang();

	//三方应用内，用蒙层iframe的方式打开。
	// app里，新开webview
	//浏览器里，新开tab
	if (device.isOscarApp) {
		openInnerWebview(
			`${window.location.origin}/help-center/${path}?locale=${locale}`,
			I18N.auto.helpCenter,
			() => {}
		);
	} else if (device.isInThirdApp) {
		IframeModal.alert({
			title: I18N.auto.helpCenter,
			url: `${window.location.origin}/help-center/h5?locale=${locale}&inIframe=true`,
			// url: `https://help-center.oscar.netease.com/h5?local=zh-CN`,
			// url:`http://10.221.192.150:8000/localAPi/error`
		});
	} else {
		const url = `${window.location.origin}/help-center/${path}?locale=${locale}`;
		if (device.isMobile) {
			openUrlWithFrom(url);
		} else {
			safeOpenUrl(url);
		}
	}
}
