import { scoutTrackError } from './scout';
import { ScoutApiNotAvailabelError } from './trackKeys';

const testLocalStorage = () => {
	const mod = 'modernizr';
	try {
		localStorage.setItem(mod, mod);
		localStorage.removeItem(mod);
		return true;
	} catch (e) {
		return false;
	}
};

/** api检测 */
export const apiTest = () => {
	// 检查eventlistner
	const testEventListnerResult = 'addEventListener' in window;
	if (!testEventListnerResult) {
		console.warn('[eventlistner] not availabel', navigator.userAgent);
		scoutTrackError({
			name: ScoutApiNotAvailabelError,
			message: `[eventlistner] not availabel, userAgent: ${navigator.userAgent}`,
		});
	}
	// 检查localStorage
	const testLocalStorageResult = testLocalStorage();
	if (!testLocalStorageResult) {
		console.warn('[localStorage] not availabel', navigator.userAgent);
		scoutTrackError({
			name: ScoutApiNotAvailabelError,
			message: `[localStorage] not availabel, userAgent: ${navigator.userAgent}`,
		});
	}
};
