import PageBox from '@/components/pageBox';
import { OSCAR_USER_NAME } from '@/const';
import useShowInput from '@/hooks/useInputShow';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './h5.less';
import { Props } from './index';

export const Wrapper: FC<Props> = ({
	children,
	title,
	username = true,
	header,
	className,
	bottomNode,
	footer,
	contentClassName,
}) => {
	const account = localStorage.getItem(OSCAR_USER_NAME);
	useShowInput();

	return (
		<PageBox bottomNode={bottomNode}>
			<div className={classNames('flex flex-col justify-center', styles['wrapper'], className)}>
				{header ? (
					<div className={classNames('flex items-center ', styles.header)}>{header}</div>
				) : (
					<div className={styles.header}>
						{title && (
							<div className={classNames(styles.title, { [styles.noname]: !username })}>
								{title}
							</div>
						)}
						{username && <div className={classNames('flex', styles.username)}>{account}</div>}
					</div>
				)}
				<div className={classNames(styles['wrapper-content'], contentClassName)}>{children}</div>
				<div className={styles['wrapper-footer']}>{footer}</div>
			</div>
		</PageBox>
	);
};

export default Wrapper;
