// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".help-btn___By32A {\n  width: 2.4rem;\n  height: 2.4rem;\n  bottom: 2rem;\n  right: 1.6rem;\n  border-radius: 50%;\n  box-shadow: 0rem 0.4rem 1rem rgba(95, 104, 122, 0.16);\n  cursor: pointer;\n  background-color: #fff;\n  position: fixed;\n}\n.help-btn___By32A .rock-icon {\n  font-size: 1.2rem;\n  color: var(--n70);\n}\n@supports (bottom: env(safe-area-inset-bottom)) {\n  .help-btn___By32A {\n    bottom: 2rem;\n  }\n}\n@supports (bottom: constant(safe-area-inset-bottom)) {\n  .help-btn___By32A {\n    bottom: 2rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/helpBtn/h5.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,qDAAA;EACA,eAAA;EACA,sBAAA;EACA,eAAA;AACD;AAVA;EAYG,iBAAA;EACA,iBAAA;AACH;AAGA;EACC;IACC,YAAA;EADA;AACF;AAGA;EACC;IACC,YAAA;EADA;AACF","sourcesContent":[".help-btn {\n\twidth: 48px;\n\theight: 48px;\n\tbottom: 40px;\n\tright: 32px;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 8px 20px rgba(95, 104, 122, 0.16);\n\tcursor: pointer;\n\tbackground-color: #fff;\n\tposition: fixed;\n\t:global {\n\t\t.rock-icon {\n\t\t\tfont-size: 24px;\n\t\t\tcolor: var(--n70);\n\t\t}\n\t}\n}\n@supports (bottom: env(safe-area-inset-bottom)) {\n\t.help-btn {\n\t\tbottom: max(calc(env(safe-area-inset-bottom) + 6px), 40px);\n\t}\n}\n@supports (bottom: constant(safe-area-inset-bottom)) {\n\t.help-btn {\n\t\tbottom: max(calc(constant(safe-area-inset-bottom) + 6px), 40px);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"help-btn": "help-btn___By32A"
};
export default ___CSS_LOADER_EXPORT___;
