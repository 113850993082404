import { Models } from '@rematch/core';
import { app } from './app';
import { next } from './next';
import { oscarVerify } from './oscarVerify';

export interface RootModel extends Models<RootModel> {
	next: typeof next;
	app: typeof app;
	oscarVerify: typeof oscarVerify;
}
export const models: RootModel = { next, app, oscarVerify };
