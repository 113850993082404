import { Icon, SlidePanel } from '@/components';
import { RootState } from '@/models/store';
import I18N from '@/utils/I18N';
import { CaretDown, DownloadLine } from '@bedrock/icons-react';
import IndexBar from '@popo-open/index-bar';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './mobile.less';

interface Props {
	onChange?: (value: string, code: string) => void;
	areaCode: string;
}
export const MobileAreaCode = (props: Props) => {
	const { onChange, areaCode: defaultValue } = props;
	const [areaCode, setAreaCode] = useState('');
	const [phoneCode, setPhoneCode] = useState('');
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState(false);
	const { mobileAreaCodeList } = useSelector((state: RootState) => state.app);

	useEffect(() => {
		setAreaCode(defaultValue);
		setPhoneCode(defaultValue.slice(0, defaultValue.indexOf(' ')));
	}, [defaultValue]);

	return (
		<>
			<SlidePanel
				title={I18N.auto.selectCountryAnd}
				height={'calc(100% - 8px)'}
				visible={visible}
				onClose={() => {
					setPhoneCode(defaultValue.slice(0, defaultValue.indexOf(' ')));
					setAreaCode(defaultValue);
					setSelected(false);
					setVisible(false);
				}}
				onFinish={() => {
					if (!selected) return;
					onChange && onChange(areaCode as string, phoneCode);
					setPhoneCode('');
					setSelected(false);
					setVisible(false);
				}}
				finishBtnStyle={{ color: selected ? '#2675FC' : '#BFBFBF' }}
			>
				<div className={classNames(styles.indexBarWrap)}>
					<IndexBar sticky={false} className={styles.indexBar}>
						{mobileAreaCodeList.map((i, index) => (
							<IndexBar.Panel
								className={styles.indexBarPanel}
								index={i.pinned ? 'top' : i.sortPrefix!}
								title={i.pinned ? '' : i.sortPrefix}
								brief={
									i.pinned ? (
										<DownloadLine style={{ transform: 'rotate(-180deg)', marginTop: 2 }} />
									) : (
										i.sortPrefix
									)
								}
								hidden={index === 0}
								key={`${i.sortPrefix}-${index}`}
							>
								{i.children?.map((i, childIndex) => (
									<div key={childIndex} className={classNames(styles.list_item)}>
										<div className={styles.list_item_border}>
											<div
												onClick={() => {
													setAreaCode(`${i.phoneCode} (${i.name})`);
													setPhoneCode(i.phoneCode);
													setSelected(`${i.phoneCode} (${i.name})` !== defaultValue);
												}}
												style={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<span className={styles.list_item_left}>{i.phoneCode}</span>
												<span className={styles.list_item_right}>{i.name}</span>
												{areaCode === `${i.phoneCode} (${i.name})` && <Icon name="icon-check" />}
											</div>
										</div>
									</div>
								))}
							</IndexBar.Panel>
						))}
					</IndexBar>
				</div>
			</SlidePanel>
			<div
				onClick={() => setVisible(true)}
				className={classNames(
					'flex items-center justify-between',
					styles['mobile-area-code-custom-select']
				)}
				style={{ width: 90, marginRight: 10 }}
			>
				<div className={styles.value}>{phoneCode}</div>
				<CaretDown />
			</div>
		</>
	);
};

export default MobileAreaCode;
