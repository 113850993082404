// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper___l_wjU {\n  padding: 0rem;\n}\n.wrapper___l_wjU .header___IL_O2 {\n  margin-bottom: 0.8rem;\n}\n.wrapper___l_wjU .title___d_rvd {\n  color: var(--PopoTextPrimary);\n  font-weight: 600;\n  font-size: 1.4rem;\n  line-height: 1.8rem;\n  margin-bottom: 0.3rem;\n  text-align: left;\n}\n.wrapper___l_wjU .username___MoBPv {\n  width: 100%;\n  font-weight: 400;\n  font-size: 0.8rem;\n  line-height: 1.1rem;\n  color: var(--PopoTextTertiary);\n  word-break: break-all;\n  align-items: flex-start;\n  letter-spacing: 0.01rem;\n}\n.wrapper___l_wjU .wrapper-content___am1gv {\n  margin: 0.8rem 0;\n  width: 100%;\n}\n.wrapper___l_wjU .wrapper-footer___BaAis {\n  width: 100%;\n  margin: 0.8rem 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/wrapper/h5.less"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;AAFA;EAIE,qBAAA;AACF;AALA;EAOE,6BAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAbA;EAkBE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;EACA,uBAAA;EACA,uBAAA;AAFF;AAvBA;EA4BE,gBAAA;EACA,WAAA;AAFF;AA3BA;EAgCE,WAAA;EACA,gBAAA;AAFF","sourcesContent":[".wrapper {\n\tpadding: 0px;\n\n\t.header {\n\t\tmargin-bottom: 16px;\n\t}\n\t.title {\n\t\tcolor: var(--PopoTextPrimary);\n\t\tfont-weight: 600;\n\t\tfont-size: 28px;\n\t\tline-height: 36px;\n\t\tmargin-bottom: 6px;\n\t\ttext-align: left;\n\t}\n\t// .noname {\n\t// \tmargin-bottom: 32px;\n\t// }\n\t.username {\n\t\twidth: 100%;\n\t\tfont-weight: 400;\n\t\tfont-size: 16px;\n\t\tline-height: 22px;\n\t\tcolor: var(--PopoTextTertiary);\n\t\tword-break: break-all;\n\t\talign-items: flex-start;\n\t\tletter-spacing: 0.2px;\n\t}\n\t.wrapper-content {\n\t\tmargin: 16px 0;\n\t\twidth: 100%;\n\t}\n\t.wrapper-footer {\n\t\twidth: 100%;\n\t\tmargin: 16px 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper___l_wjU",
	"header": "header___IL_O2",
	"title": "title___d_rvd",
	"username": "username___MoBPv",
	"wrapper-content": "wrapper-content___am1gv",
	"wrapper-footer": "wrapper-footer___BaAis"
};
export default ___CSS_LOADER_EXPORT___;
