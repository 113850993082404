import { useThrottleFn } from 'ahooks';
import { useCallback, useEffect, useRef } from 'react';

const originHeight = document.documentElement.clientHeight || document.body.clientHeight;

const useShowInput = () => {
	const scrollTop = useRef(0);
	const handleFocus = useCallback(() => {
		scrollTop.current = document.documentElement.scrollTop;
		setTimeout(() => {
			(document.getElementById('isMobile') as HTMLDivElement).scrollTop =
				document.body.scrollHeight;
		}, 300);
	}, []);

	const handleBlur = useCallback(() => {
		(document.getElementById('isMobile') as HTMLDivElement).scrollTop = scrollTop.current;
	}, []);

	const { run: handleResize } = useThrottleFn(
		() => {
			const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
			if (resizeHeight < originHeight) {
				// 键盘弹起所后所需的页面逻辑

				const activeDom = document.activeElement;

				if (activeDom) {
					activeDom.scrollIntoView(false);
				}
			} else {
				(document.getElementById('isMobile') as HTMLDivElement).scrollTop = 0;
			}
		},
		{ wait: 150 }
	);

	useEffect(() => {
		const ua = window.navigator.userAgent.toLocaleLowerCase();
		const isIOS = /iphone|ipad|ipod/.test(ua);
		const isAndroid = /android/.test(ua);
		const inputs = document.getElementsByTagName('input');
		if (isIOS) {
			if (inputs) {
				// @ts-ignore
				Array.from(inputs).forEach((input: HTMLDivElement) => {
					input.addEventListener('focus', handleFocus);
					input.addEventListener('blur', handleBlur);
				});
			}
		} else if (isAndroid) {
			window.addEventListener('resize', handleResize);
			if (inputs) {
				// @ts-ignore
				Array.from(inputs).forEach((input: HTMLDivElement) => {
					input.addEventListener('focus', handleResize);
				});
			}
		}
		return () => {
			if (isIOS) {
				if (inputs) {
					// @ts-ignore
					Array.from(inputs).forEach((input: HTMLDivElement) => {
						input.removeEventListener('focus', handleFocus);
						input.removeEventListener('blur', handleBlur);
					});
				}
			} else if (isAndroid) {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, []);
};

export default useShowInput;
