import selectSearchEmpty from '@/assets/images/selectSearchEmpty.png';
import { Input, Select } from '@/components';
import { Dispatch, RootState } from '@/models/store';
import I18N from '@/utils/I18N';
import { Check } from '@bedrock/icons-react';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import MobileAreaCode from './mobile';

interface Props {
	onChange?: (value: string, code: string) => void;
	defaultValue: string;
}
export const AreaCode = (props: Props) => {
	const { onChange, defaultValue } = props;
	const dispatch = useDispatch<Dispatch>();
	const [areaCode, setAreaCode] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const { areaCodeList, isMobile } = useSelector((state: RootState) => state.app);
	const [list, setList] = useState(areaCodeList);

	useEffect(() => {
		setList(areaCodeList);
	}, [areaCodeList]);

	useEffect(() => {
		dispatch.app.getAreaCodeList({});
	}, []);

	useEffect(() => {
		setAreaCode(defaultValue);
	}, [defaultValue]);

	const onSearch = (e: any) => {
		const value = e.target.value;
		const lowerValue = value.toLocaleLowerCase();
		setSearchValue(value);
		const searchList = areaCodeList.filter((item) =>
			(item.label as string).toLocaleLowerCase().includes(lowerValue)
		);
		setList(value !== '' ? [...searchList] : [...areaCodeList]);
	};

	return (
		<>
			{isMobile ? (
				<MobileAreaCode
					areaCode={areaCode}
					onChange={(areaCode: string, phoneCode: string) => {
						setAreaCode(areaCode);
						onChange && onChange(areaCode, phoneCode);
					}}
				/>
			) : (
				<Select
					fill
					value={areaCode}
					autoMaxHeight={236}
					virtual
					notFoundContent={
						<div className="notFoundContent flex flex-col items-center justify-center">
							<img src={selectSearchEmpty} alt="" />
							<span className="text">{I18N.auto.noResultFound}</span>
						</div>
					}
					fieldNames={{ name: 'name', value: 'label' }}
					dropdownClassName="area-code-dropdown"
					renderBeforeSearch={() => (
						<div style={{ padding: '0px 8px', marginBottom: 4 }}>
							<Input.Search
								onChange={onSearch}
								style={{ width: '100%' }}
								value={searchValue}
								onClear={() => setList([...areaCodeList])}
								placeholder={I18N.auto.searchPlaceholder}
							/>
						</div>
					)}
					renderLabel={(option) => (
						<div className="flex items-center justify-between">
							<Highlighter
								highlightClassName="highlight"
								searchWords={[searchValue]}
								autoEscape={true}
								textToHighlight={option.label as string}
							/>
							{areaCode === option.value && <Check style={{ fontSize: 20 }} />}
						</div>
					)}
					onChange={(value, params: any) => {
						setAreaCode(value as any);
						onChange && onChange(value as string, params.option.name);
						setTimeout(() => {
							setSearchValue('');
							setList([...areaCodeList]);
						}, 400);
					}}
					placeholder={I18N.auto.pleaseSelect}
					options={list}
					style={{ width: 90, marginRight: 10 }}
				/>
			)}
		</>
	);
};

export default AreaCode;
