// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".help-btn___VnWQC {\n  cursor: pointer;\n  color: var(--n60);\n  font-size: 0.6rem;\n  line-height: 0.9rem;\n}\n.help-btn___VnWQC .rock-icon {\n  font-size: 0.8rem;\n  color: var(--n60);\n  margin-right: 0.2rem;\n}\n.help-btn___VnWQC:hover {\n  color: var(--br50);\n}\n.help-btn___VnWQC:hover .rock-icon {\n  color: var(--br50);\n}\n", "",{"version":3,"sources":["webpack://./src/components/helpBtn/pc.less"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AACD;AALA;EAOG,iBAAA;EACA,iBAAA;EACA,oBAAA;AACH;AAEC;EACC,kBAAA;AAAF;AADC;EAIG,kBAAA;AAAJ","sourcesContent":[".help-btn {\n\tcursor: pointer;\n\tcolor: var(--n60);\n\tfont-size: 12px;\n\tline-height: 18px;\n\t:global {\n\t\t.rock-icon {\n\t\t\tfont-size: 16px;\n\t\t\tcolor: var(--n60);\n\t\t\tmargin-right: 4px;\n\t\t}\n\t}\n\t&:hover {\n\t\tcolor: var(--br50);\n\t\t:global {\n\t\t\t.rock-icon {\n\t\t\t\tcolor: var(--br50);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"help-btn": "help-btn___VnWQC"
};
export default ___CSS_LOADER_EXPORT___;
