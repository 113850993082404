import { Button, Spin } from '@/components';
import device from '@/utils/device';
import I18N from '@/utils/I18N';
import { Close } from '@bedrock/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import s from './index.less';

type Props = {
	url: string;
	title: string;
	onClose?: () => void;
};

//通过iframe的方式，展示帮助中心和协议
export default function IframeModal({ url, title, onClose }: Props) {
	const [loading, setLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const iframeRef = useRef<HTMLIFrameElement>(null);

	let containerRef = useRef<HTMLDivElement>(null);

	if (!containerRef.current) {
		//@ts-ignore
		containerRef.current = document.createElement('div') as HTMLDivElement;
		document.body.appendChild(containerRef.current);
	}
	useEffect(() => {
		if (!url.startsWith(location.origin)) {
			console.warn('iframe url orgin is cors');
		}
	}, []);
	return createPortal(
		<div className={`${s.iframeModalRoot} ${device.isMobile ? s.mobile : ''}`}>
			<div className={s.mask}></div>
			<div className={s.modalWrap}>
				<div className={`${s.content}`}>
					<div className={s.header}>
						<div className={s.title}>{title}</div>
						<Close className={s.close} size={24} onClick={onClose}></Close>
					</div>
					<div className={s.iframeWrap}>
						<iframe
							ref={iframeRef}
							name={title}
							src={url}
							className={s.iframe}
							onError={() => {
								setHasError(true);
								setLoading(false);
							}}
							onLoad={() => {
								try {
									let rootDom = iframeRef.current?.contentWindow?.document.querySelector('#root');
									if (!rootDom) {
										setHasError(true);
									}
								} catch (err: any) {
									//
								}
								setLoading(false);
							}}
						></iframe>
						{loading && (
							<div className={`${s.contentMask}`}>
								<Spin.Global className={s.spin}></Spin.Global>
							</div>
						)}
						{hasError && (
							<div className={`${s.contentMask}`}>
								<div className={s.errorDesc}>{I18N.auto.pageLoadFailedAndRetry}</div>
								<Button
									type="primary"
									className={s.reloadBtn}
									onClick={() => {
										if (iframeRef.current) {
											setLoading(true);
											setHasError(false);
											iframeRef.current.src += '';
										}
									}}
								>
									{I18N.auto.reload}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>,
		containerRef.current
	);
}

IframeModal.alert = function Alert(props: Props) {
	let div = document.createElement('div');
	document.body.append(div);

	let root = createRoot(div);
	root.render(
		<IframeModal
			{...props}
			onClose={() => {
				root.unmount();
				document.body.removeChild(div);
			}}
		></IframeModal>
	);
};
