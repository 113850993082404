import { Message } from '@/components';
import I18N, { getCurrentLang, LangEnum, setLang } from '@/utils/I18N';
import { Globe } from '@bedrock/icons-react';
import { useMemo } from 'react';
import Dropdown from '../basic/dropdown';
import { trackBussinessEvent } from '@/utils/countly';
import { CountlyChangeLang } from '@/utils/trackKeys';
import './index.less';

export default function LocaleSetting() {
	let curLocale = getCurrentLang();
	console.log(curLocale, 'curLocale');

	const localeList = useMemo(() => {
		const changeLang = (lang: string | number) => {
			trackBussinessEvent({
				event: CountlyChangeLang,
				custom: {
					lang,
				},
			});
			setLang(lang as LangEnum);
			// // error test
			// window.testError.test();
			Message.loading(I18N.auto.languageSwitching);
			window.location.reload();
		};
		return [
			{
				label: I18N.common.chinese,
				onClick: changeLang,
				key: LangEnum['zh-CN'],
			},
			{
				label: I18N.common.english,
				onClick: changeLang,
				key: LangEnum['en-US'],
			},
			{
				label: I18N.common.japanese,
				onClick: changeLang,
				key: LangEnum['ja-JP'],
			},
			{
				label: I18N.common.french,
				onClick: changeLang,
				key: LangEnum['fr-CA'],
			},
		];
	}, []);

	return (
		<Dropdown options={localeList} selectMenu={curLocale} placement="topLeft">
			<Dropdown.Button className={'localeSettingBtn'} type="text" icon={<Globe size={16}></Globe>}>
				{localeList.find((item) => item.key === curLocale)!.label}
			</Dropdown.Button>
		</Dropdown>
	);
}
