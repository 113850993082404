import { CSSProperties } from 'react';
import device from '@/utils/device';
import H5 from './h5';
import PC from './pc';

export interface Props {
	value?: string;

	disabled?: boolean;
	onFinish?: (value: string) => void;
	onChange?: (value: string) => void;
	length?: number;
	autoFocus?: boolean;
	className?: string | undefined;
	style?: CSSProperties | undefined;
	errorMessage: string;
}
export default function Wrapper(props: Props) {
	let Com = device.isMobile ? H5 : PC;

	return <Com {...props}></Com>;
}
