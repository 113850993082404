import { RootState } from '@/models/store';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Props } from '.';

import styles from './h5.less';

const DEFAULT_LENGTH = 6;

const Captcha: React.FC<Props> = (props) => {
	const {
		value = '',
		onChange,
		onFinish,
		length = DEFAULT_LENGTH,
		autoFocus = false,
		className,
		disabled = false,
		style,
		errorMessage: propsErrorMessage,
	} = props;
	const [inputValue, setInputValue] = useState('');
	const codeArray = useMemo(() => {
		return new Array(length).fill('').map((item, index) => inputValue[index] || '');
	}, [inputValue, length]);
	const { isMobile } = useSelector((state: RootState) => state.app);
	const [isFocused, setFocus] = useState(false);
	const [errorMessage, setErrorMessage] = useState(propsErrorMessage);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setErrorMessage(propsErrorMessage);
	}, [propsErrorMessage]);

	useEffect(() => {
		// 仅支持数字，后续可拓展
		const tempValue = value.replace(/[^0-9]/g, '').slice(0, length);
		setInputValue(tempValue);
	}, [value, length]);

	useEffect(() => {
		if (autoFocus && !isMobile) {
			inputRef.current?.focus();
			setFocus(true);
		}
	}, [autoFocus, isMobile]);

	const handleInputCodeChange = (e: any) => {
		const eValue = e.target.value;
		const tempValue = eValue.replace(/[^0-9]/g, '').slice(0, length);

		setInputValue(tempValue);
		if (errorMessage !== '') {
			setErrorMessage('');
		}
		if (onFinish && tempValue.length === length) {
			inputRef.current?.blur();
			onFinish(tempValue);
		}
		onChange?.(tempValue);
	};

	const handleCodeBoxClick = (e: any) => {
		e.preventDefault();
		inputRef.current?.focus();
		setFocus(true);
	};

	return (
		<div
			className={classNames('w-full flex', styles.captcha, {
				className,
			})}
			style={{ ...style }}
		>
			<div className="w-full flex flex-col">
				<div
					className={classNames('w-full flex', styles['code-box'])}
					onMouseDown={handleCodeBoxClick}
				>
					{codeArray.map((item, index, array) => {
						const prevItemValue = index === 0 ? '-1' : array[index - 1]; // 第一项视为前一项有值
						const isItemEmptyActive = isFocused && !!prevItemValue && !item; // Input 闪烁条展示在前一项有值且当前项为空的位置
						const isItemActive = isFocused && inputValue.length === 6 && index === 5;

						return (
							<div
								key={index}
								className={classNames('flex items-center justify-center', styles['item-content'], {
									[styles['item-content-empty-active']]: isItemEmptyActive,
									[styles['item-content-active']]: isItemActive,
									[styles.error]: !!errorMessage,
									[styles.disabled]: disabled,
								})}
							>
								{item}
							</div>
						);
					})}
				</div>
				{errorMessage !== '' && <div className={styles['error-message']}>{errorMessage}</div>}
			</div>

			<div className={styles['input-box-wrap']}>
				<input
					type="text"
					value={inputValue}
					onChange={handleInputCodeChange}
					onFocus={() => {
						setFocus(true);
					}}
					onBlur={() => {
						setFocus(false);
					}}
					pattern="[0-9]*"
					autoComplete="one-time-code"
					inputMode="numeric"
					disabled={disabled}
					// maxLength={length}
					ref={inputRef}
				/>
			</div>
		</div>
	);
};

export default Captcha;
