import { Select as BasicSelect } from '@bedrock/components';
import { SelectProps } from '@bedrock/components/es/Select';
import './index.less';
// import { CaretDown } from '@bedrock/icons-react';

interface Props extends SelectProps {
	/*因为bedrock支持语种有限，文案全都要避免走默认值 */
	placeholder: string;
}

// export default function Select(props: Props) {
// 	return <BasicSelect  arrow={<CaretDown></CaretDown>} {...props}></BasicSelect>;
// }

const Select: React.FC<Props> = BasicSelect;

export default Select;
