import { ChevronLeft } from '@bedrock/icons-react';
import classNames from 'classnames';
import { Button } from '@/components';
import styles from './h5.less';
import { ComProps } from '.';

export const GoBack = (props: ComProps) => {
	const { options, textButton = true } = props;

	return (
		<div
			className={classNames('flex flex-col', styles['go-back-wrap'], {
				'w-full': !textButton,
			})}
		>
			{options.map((item) => {
				return item.visible ? (
					textButton ? (
						<div
							className={classNames('flex items-center', styles['go-back-item'])}
							key={item.text}
							onClick={item.handler}
						>
							<ChevronLeft />
							<span className={styles.text}>{item.text}</span>
						</div>
					) : (
						<Button onClick={item.handler}>{item.text}</Button>
					)
				) : null;
			})}
		</div>
	);
};

export default GoBack;
