import I18N from '@/utils/I18N';
import openHelpCenter from '@/utils/openHelpCenter';
import { HelpCircle } from '@bedrock/icons-react';
import classNames from 'classnames';
import { useCallback } from 'react';
import styles from './pc.less';

interface Props {}

const HelpBtnPc = (props: Props) => {
	const onClick = useCallback(() => {
		openHelpCenter();
	}, []);

	return (
		<div
			className={classNames('flex items-center justify-center', styles['help-btn'], styles.isPc)}
			onClick={onClick}
		>
			<HelpCircle />
			<span>{I18N.auto.helpCenter}</span>
		</div>
	);
};

export default HelpBtnPc;
