export enum HandlerType {
	AccountCheck = 2,
	PasswordSet = 3,
	ActiveSuccess = 4,
	ResetSuccess = 5,
	RecoverPasswordWithLink = 6,
	RecoverPasswordWithCode = 7,
	OscarVerifyBindLinkTransfer = 8,
	OscarVerifyBindQrcode = 9,
	OscarVerifyBindLink = 10,
	OscarVerifyBindLinkWait = 11,
	PhoneSet = 12,
	PhoneSetOtpVerify = 13,
	OscarVerifyUnbind = 14,
	RecoverPasswordCancel = 15,
	RecoverPasswordModifiedByOther = 16,
	RedirectToBindOscarVerifyInAppAfterLogin = 17,
	PhoneDelete = 18,
	SecondConfirmWithCurrentAccount = 20,
	EmailSet = 21,
	EmailSetOtpVerify = 22,
	EmailDelete = 23,
	SSAEAccountCheck = 51,
	SSAEVerifyValidationEmail = 52,
	SSAESendActivationEmailTooMuch = 53,
	SSAESendActivationEmailSuccess = 54,
	SSAEAccountUnableToActive = 55,
	SSAESelfActivationNotOpen = 56,
	OtpVerifyLimit = 57,
	OtpPhoneVerifyLimit = 58,
	SelectOtpVerifyLimit = 59,
	SelectOtpPhoneVerifyLimit = 60,
	AuthenticatorSelectConfirmList = 101,
	AuthenticatorSelectSetList = 102,
	AuthenticatorPassword = 103,
	AuthenticatorOscarVerifyPush = 104,
	AuthenticatorOscarVerifyOTP = 105,
	AuthenticatorEmailVerify = 106,
	AuthenticatorPhone = 107,
	OtherAuthenticatorSelectSetList = 108,
	AuthenticatorPasswordTemp = 109,
	PreAuthenticatorEmail = 110,
	PreAuthenticatorEmailSend = 111,
	AuthenticatorEmailCodeDisplay = 112,
	AccountFailure = 402,
	AccountLocked = 403,
	SessionTimeout = 404,
	AccountUnLocked = 405,
	ActiveLinkRot = 406,
	ResetPasswordLinkRot = 407,
	RecoverPasswordLinkRot = 408,
	OscarVerifyBindActiveLinkRot = 409,
	CanNotRecoverPassword = 410,
	UnknownError = 411,
	AuthenticatorEmailLinkExpired = 412,
	RedirectTo = 901,
	DoNothing = 999,
}

/** 应用登录方式 */
export enum AppLoginMethodType {
	/** 直接登录，默认 */
	Direct = 0,
	/** 选择登录 */
	Select,
}

/** 应用登录入口类型 */
export enum AppLoginEntranceType {
	Oscar = 0,
	OpenID,
}
