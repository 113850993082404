import { Icon } from '@/components';
import I18N from '@/utils/I18N';
import classNames from 'classnames';
import React from 'react';
import styles from './index.less';

interface Props {
	title: string;
	children: React.ReactNode | string;
	visible: boolean;
	onClose: () => void;
	onFinish: () => void;
	height: number | string;
	finishBtnStyle?: any;
}

const SlidePanel: React.FC<Props> = (props: Props) => {
	const { title, children, onClose, visible, height, onFinish, finishBtnStyle } = props;
	const [bottom, setBottom] = React.useState(`-${height}px`);
	const contentRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		setBottom(visible ? '0px' : `-100%`);
		// 防止滚动穿透
		document.body.style.overflow = visible ? 'hidden' : 'auto';
	}, [visible]);

	return (
		<div
			style={{ display: visible ? 'flex' : 'none' }}
			className={classNames(styles['slide-panel-wrap'])}
		>
			<div className={styles['slide-panel-mask']} onClick={() => onClose && onClose()}></div>
			<div className={classNames(styles['slide-panel'])} style={{ bottom, height }}>
				<div className={styles['panel-header']}>
					<div className="flex" onClick={() => onClose && onClose()}>
						<Icon name="icon-close_1" />
					</div>
					<span className={styles.title}>{title}</span>
					<div
						className={styles.done}
						style={{ ...finishBtnStyle }}
						onClick={() => onFinish && onFinish()}
					>
						{I18N.auto.complete}
					</div>
				</div>
				<div className={styles['panel-content']} ref={contentRef}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default SlidePanel;
