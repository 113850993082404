export default {
	announcement1:
		'Ngpushは、2024年1月15日（月）の11:00～11:30（GMT+08）にメンテナンスを実施します。この間、Oscar Verifyのプッシュまたは認証機能がご利用いただけません。ご不便をおかけしまして申し訳ございません。',
	continueWithOp: 'OpenID でログイン',
	continueWithOs: 'Oscar でログイン',
	sendMeEmail: 'メールを送信',
	clickSendMeEmail: '「{val1}」をタップして、認証コードを送信します。',
	getVerifyEmail: 'ご本人確認のお願い',
	phoneSettingTips: '注意',
	readAndAgree2: '{val1}に同意してください',
	seviceAgreement: '利用規約',
	hasActivatedYour:
		'あなたのアカウント{val1}の有効化が完了しました。作業台を使用するには認証方法を設定する必要があります。',
	inYourFrequentlyUsedMobile:
		'Oscar Verify アプリをインストールしたデバイスを「認証用デバイス」に設定すると、Oscar verify アプリから認証コードを取得することができます',
	enableSecurityVerification: '本人確認方法の設定',
	afterActivationYouCan:
		'設定後、対応する方法を使用して本人確認を行い、Oscar アカウントにログインできます。',
	enableOsc: 'Oscar Verify 認証の設定',
	step1Download: 'ステップ１、アプリのダウンロード',
	youNeedToUse: 'モバイルデバイスでQR コードをスキャンして、 Oscar Verify アプリをダウンロードする',
	oscarVerify: 'Oscar Verify モバイル版',
	downloadedApp: 'アプリをダウンロードした。設定を開始する',
	step2Binding: 'ステップ２、アカウントの登録',
	selectInTheApplication:
		'アプリで「アカウントを登録」-「スキャンする」を選択し、下の QR コードをスキャンして、アカウントを現在のデバイスに登録してください。登録が完了すると、ページが自動的にリロードされます。',
	bindQrCode: '登録用 QR コード',
	switchToOtherBindings: '他の登録方法を使用する',
	notDownloadedYet: 'まだアプリをダウンロードしていない？',
	scanCodeToDownloadO_2: 'スキャンして Oscar Verify をダウンロード',
	viewDownload2: 'ダウンロードする',
	throughMessageHorizontal: '通知バナーまたはアプリ内通知により、クイック認証を行う',
	withinTheApplication: 'Oscar Verify に生成された認証コードを使用して、本人確認を行う',
	oscar_2: 'Oscar Verifyには2つの認証方法がある：',
	enableMobileVerification: 'SMS認証の設定',
	logInToTheApp: '以下のアプリにログイン',
	unableToRetrieve: 'リセットできません',
	enableSecurityVerification_2: '本人確認方法を設定する',
	startCertification: '本人確認方法を設定する',
	validateEmailTips:
		'{val1}にメールを送信しました。メールで受け取った認証コードを入力してください。',
	validateEmailTips2: '「メールを送信する」ボタンをクリックして、{val1}にメールを送信します。',
	verifyEmailSendTips:
		'{val1}にメールを送信しました。メールに記載された URLをクリックして操作を続けるか、{val2}を入力してください。',
	emailVerification: 'メールで認証する',
	sendingAnEmail: 'メールを送信する',
	pleaseResend: 'メールに記載されたURLに再度アクセスしてください',
	setUpEmailVerification: '認証用メールの設定',
	pleaseVerifyInTheEmail: 'メールに以下の認証コードを入力してください',
	ifYouDontHave: '',
	verifyThatTheEmailHasBeenSent: '送信完了',
	aiden: 'これらをクリックして、受け取ったコード',
	byBound_2: '認証用メールで認証する',
	byBindingO: '認証用デバイスで認証する',
	throughOsc_2: 'Oscar verify アプリで認証する',
	enable: '設定',
	toEnsureTheAccount:
		'セキュリティを確保するために、ご本人が使用しているメールアドレスを入力してください',
	pleaseEnterYour_2: 'メールアドレスを入力してください',
	emailVerification_2: 'メール',
	bindEmailAccount: '多要素認証用メールを設定すると、メールで認証コードを取得することができます',
	smsVerification: 'SMS',
	bindCommonlyUsedHands: 'よく使う携帯電話を設定すると、SMSで認証コードを取得することができます',
	onMobileDevices:
		'Oscar Verify アプリをインストールしたデバイスを「認証用デバイス」に設定すると、Oscar verify アプリから認証コードを取得することができます',
	sent: '送信回数が上限に達しました',
	verificationEmailSent:
		'認証コードの送信回数が上限に達しました。受信箱を確認するか、別の方法を選択してください',
	verifySmsSending:
		'認証コードの送信回数が上限に達しました。携帯番号が正しいかを確認するか、別の方法を選択してください',
	linkExpired: '期限切れ',
	thisEmail: 'このURLの有効期限が切れています。ログインページへ戻って、再送信してください',
	cancel: 'キャンセル',
	nextStep2: '次へ',
	Recover: 'リセットする',
	notFound: '一致する結果が見つかりません',
	langSetting: '言語設定',
	languageSwitching: '言語を変更中…',
	searchPlaceholder: '検索',
	noResultFound: '一致する結果がありません',
	termsOfService: '利用規約',
	loginTips: '注意',
	readAndAgree: '{val1} を同意してください',
	iAgree: '{val1} に同意する',
	Agree: '同意',
	NoPermission: '権限なし',
	UnableToSign: 'あなたのアカウントは現在ログインできません',
	xiaZaiYingYong: 'アプリ入手',
	bangDingSheBei: 'デバイス登録',
	shangYiBu: '前へ',
	unableActivate: 'あなたのアカウント{val1}は有効化できません',
	exceedActivateTimes:
		'アカウント{val1}の本日の有効化回数が制限に達したため、カウントダウンが終了してから再度お試しください',
	activateEmailSendTips:
		'{val1} にメールが送信されました。メール内のurlにアクセスして、アカウントを有効にしてください。',
	validateActivateEmailTips:
		'{val1}にメールが送信されました。下記空欄に認証コードを入力して認証を完成してください。',
	exceptionReminder: 'エラー',
	notAtPresent: '現在、アカウント有効化のセルフサービスは提供されていません',
	unableToActivate: '有効化できません',
	activationExceeded: '有効化の回数制限を超えています',
	theActivationMessageHasBeen: '有効化メールが送信されました',
	backInHelp: 'ヘルプセンターに戻る',
	returnToAccountVerification: 'アカウント認証に戻る',
	verificationCodeHasBeenSent: '認証コードが送信されました',
	accountActivation: 'アカウント有効化',
	activateYourO: 'Oscarアカウントを有効にする',
	pleaseEnterTheRequired: '有効化するアカウントを入力',
	getVerificationCode: '認証コードを取得',
	helpCenter: 'ヘルプセンター',
	queRenDengLu: 'ログイン確認',
	queRenYiOS: 'Oscarアカウントでログインする',
	nonOverseasEmployees: '中国本土の従業員のログインをサポート',
	signInWithOpenid: 'Corpメールでログイン',
	accountNotActivated: '{val1}',
	activated: 'アカウントを有効にする',
	currentOsc:
		'Oscar アカウントは現在 OC メールボックス（oc@netese.com）にのみ有効化できます。有効化メールはあなたのOC メールボックスに送信されます.',
	pageLoadFailedAndRetry: 'ページの読み込みに失敗しました\nもう一度お試しください',
	reload: 'リロード',
	pleaseSelect: '選んでください',
	afterClickingTheButton: 'クリックすると自動的にインストールされます。ホーム画面にてご確認下さい',
	clickOnTheUpperRightCorner: '右上の（图）をタップ',
	selectToBrowse: 'ブラウザで開くを選択',
	youHaveLoggedOut: 'ログアウトしました',
	yourAccountHas: 'Oscar アカウントからログアウトしました',
	minute: '分',
	announcement:
		'Oscarチームでは、システムの安全性と安定性の向上ため、{val1}の間にシステムのアップグレードを実施いたします。メンテナンス期間は{val2}の見込みです。メンテナンス中は、アカウントにログインすることはできますが、アカウントの有効化やパスワードのリセット機能がご利用いただけません。',
	thanks:
		'ご質問等がございましたら、oscarfeedback@oc.netease.com までお気軽にお問い合わせください。',
	optional: '（オプション）',
	downloadNow: '今すぐダウンロード',
	backToPasswordVerification: 'パスワード認証に戻る',
	otherVerifiers: '他の認証方法',
	returnToSafetyInspection: '認証方法選択ページに戻る',
	backToLogin: 'ログインページに戻る',
	returnToWorkbench: '作業台に戻る',
	resend: '再送信',
	nextStep: '次へ',
	rememberAccountNumber: 'アカウントを保存する',
	pleaseEnterYour: 'OC/スタジオメールアドレス',
	signIn: 'ログイン',
	theAccountFormatIs: 'アカウント形式が間違っています。再入力してください。',
	emailFormatIsError: 'メールアドレスの形式が正しくありません。再入力して下さい',
	logInToOsc: 'Oscar 身元確認ツールにログイン',
	logInToOsc2: 'Oscar 作業台にログイン',
	logInToOsc3: 'Oscar 管理プラットフォームにログイン',
	selectSafetyInspection: '認証方法を選択',
	toVerify: '認証する',
	throughOsc: 'Oscar verify で',
	verificationCode: 'Oscar Verify 認証コード',
	pushNotification: 'Oscar Verify プッシュ通知',
	byBound: '登録済み携帯電話に認証コードを送信します',
	smsCode: 'SMS',
	autoSendPush: 'プッシュ通知を自動送信',
	resendPush: 'プッシュ通知を再送信',
	pushSent: 'プッシュ通知 送信済み',
	noPushReceived:
		'モバイルデバイスで Oscar Verify の通知を確認してください。通知がない場合は再送信可能です。\r\n',
	pushNotificationInspection: 'プッシュ通知による認証',
	pushExpired: 'プッシュ通知がタイムアウトしました',
	youJustDeclined: 'このログインを拒否しました',
	verificationNotReceived: '認証コードが届かない？',
	pleaseEnterBelow:
		'認証コードを次の携帯電話に送信完了{val1}、下の空欄に入力して認証を完了してください。',
	smsCodeVerification: 'SMS認証コードによる認証',
	verifying: '認証中',
	fromOsc: 'Oscar Verify アプリから認証コードを取得し、下の空欄に入力して認証を完了してください。',
	verificationCodeVerification: '認証コードによる認証',
	toSet: '設定へ',
	bindMobilePhoneTo: '登録された携帯電話に認証コードを送信して、身元確認を完了します',
	mobilePhoneVerification: '携帯電話による認証',
	oscar:
		'Oscar verify は、身元確認用アプリケーションです。ログイン時の認証のため、携帯電話にインストールしておくことをおすすめします。',
	afterSettingYouCan:
		'セキュリティを強固するために、ログインしているのが本人であることを確認する認証方法を設定してください。',
	setSafetyInspection: '認証方法の設定',
	sendLink: 'リンクを送信',
	pleaseEnterYour2: 'メールアドレスを入力',
	throughMailChain:
		'メールのリンクから設定を完了してください。モバイル デバイスで利用可能なメールである必要があります。',
	setOsc: 'Oscar Verifyのセットアップ',
	unableToScan: 'スキャンできない？',
	scanTheLowerTwo: 'アカウントを追加するには、下の QR コードをスキャンしてください',
	openTheAppAnd: 'アプリを開き、案内に従って「今すぐスキャン」を選択',
	scanInstallationPackage: 'ダウンロード',
	scanCodeToDownloadO: 'QR コードをスキャンして Oscar Verify アプリをダウンロード',
	downloadOsc: 'Oscar Verify アプリをダウンロード',
	scanSucceeded: 'スキャン成功',
	clickRefresh: 'クリックして更新',
	qrCodeLost: '無効なQRコードです',
	setLater: 'あとで設定する',
	afterSettingYouCan2:
		'設定後、対応する方法を使用して身元確認し、Oscar アカウントにログインできます。',
	otherSafetyInspection: '他の認証方法',
	sendVerificationCode: '認証コードを送信',
	pleaseEnterYour3: '携帯番号を入力してください',
	setMobilePhoneVerification: '認証用携帯電話の設定',
	china: '+86 (Chinese Mainland)',
	noLinkReceived: 'リンクが届かない？',
	pleaseUseMobileDevice:
		'リンクを次の携帯電話に送信完了{val1}、モバイル デバイスでリンクにアクセスして、案内に従って認証を完了してください。',
	openApp: 'アプリを開く',
	shouldBeOpened:
		'続きを完了するには、アプリケーションを開く必要があります。Oscar Verify がデバイスにインストールされていない場合は、下のボタンをクリックしてダウンロードしてください。',
	oscar2:
		'Oscar Verify は、アカウントをログインする際の2要素認証用の軽量アプリケーションです。これにより、身元確認を実行して Oscar アカウントにログインできます。',
	applyForUnlocking: '解除する',
	hopesItCanBeSolvedImmediately: 'ロックを解除しますか？',
	requestSent: '申請送信完了',
	systemOccurred:
		'システムで不明なエラーが発生しました。問題が発生した場合は、POPO / Teams / Slack から IT Helpdesk を検索してフィードバックを送信できます。',
	unknownError: '未知なエラー',
	searchIsNotSupportedTemporarily:
		'あなたのアカウント{val1}パスワードのリセットはサポートしていません。問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	yourAccount: 'あなたのアカウント',
	unableToRetrieveTheSecret: 'パスワードをリセットできません',
	refreshPage: 'ページをリフレッシュしてください',
	currentSystemRing: '現在のシステム環境が異常です。再試行下さい。',
	systemAbnormality: 'システムエラー',
	youHaveJustTaken:
		'このパスワード リセット請求をキャンセルしました。ログインページに戻ってください。',
	cancelRetrievingTheSecret: 'パスワードのリセットをキャンセル',
	youAreTryingToUse:
		'使用しようとしている URL は無効です。再送信したい場合はログインページに戻ってください。',
	linkHasExpired: '無効なURLです',
	ifYouHave:
		'パスワードをリセットした場合、または無効な URL を使用しようとした場合に発生する可能性があります。問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	ifYouHaveStimulated:
		'Oscar Verify 設定を有効にした場合、または使用しようとしている URL の有効期限が切れている場合に発生する可能性があります。ログインページに戻り、再送信してください。問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	ifYouHaveStimulated2:
		'アカウントを既に有効にしている場合、または無効な URL を使用しようとしている場合に発生する可能性があります。問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	unlockingTime: 'ロック解除時間：',
	abnormalRegistration:
		'あなたのアカウント{val1}に異常なログイン行為が検出されたため、ロックされています。ロック解除後に続行してください。{val2}',
	accountLocked: 'ロックされています',
	invalid:
		'有効期限が切れており、現在の操作を続行できません。詳細については、管理者にお問い合わせください。',
	accountInvalid: 'アカウントの有効期限が切れました',
	dueToBeingInNon:
		'しばらくの間操作がなかったため、ログアウトしました。ログインページに戻ってください。',
	sessionTimeOut: 'タイムアウト',
	noMailReceived: 'メールが届かない？',
	retrievePassword: 'パスワードを忘れた',
	itsMe: '私です',
	noNotMe: '私ではありません',
	didYouJust: '先程ログインしようとしましたか？',
	youAreAlreadyInAnother: '他のページでパスワードのリセットを実行しました。',
	pageIsBeingCalibrated: 'ページ読み込み中です。お待ち下さい',
	determine: '確認',
	pleaseEnterAgain: 'パスワードを再度入力してください',
	andTheFirstLoss: '最初の入力と一致しています',
	pleaseEnterANewSecret: '新しいパスワードを入力してください',
	setNewPassword: '新しいパスワードの設定',
	notTheSameAsTheAccountName: 'アカウント名と異なる',
	includingCapitalLetters: '大/小文字・数字・記号それぞれを最低1文字ずつ含む',
	atLeastCharacters: '8文字以上、20文字以下でお願いします。',
	operationSucceeded: '操作に成功しました',
	sPasswordHasBeenReset:
		'あなたのアカウント{val1}のパスワードはリセットされました。作業台を使用するには再認証が必要です。',
	resetSucceeded: 'リセット成功',
	refreshUnlocked:
		'あなたのアカウント{val1}のロックは解除されました。リフレッシュまたはログインページ戻って再ログインしてください。',
	accountUnlocked: 'アカウントのロックが解除されました',
	activationSucceeded: '有効化成功',
	pleaseEnterTemporary: '一時パスワードを入力',
	passwordVerification: 'パスワード認証',
	forgotPassword: 'パスワードを忘れた？',
	verification: '認証',
	inCaseOfAnyDifficulty:
		'問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	pleaseEnterTheAccountNumber: 'パスワードを入力',
	ifYouForget: 'パスワードを忘れた場合は、パスワードのリセットを試みることができます。',
	continuous:
		'{val1}回の入力間違いが発生しました。さらに{val2}回間違った場合は、アカウントが{val3}時間ロックされます。',
	tooManyErrors: '入力ミス回数が多すぎます',
	hoursIfYou2: '一時パスワードを忘れた場合は、有効化メールを確認して取得できます。',
	continueTyping: '入力を続行',
	whetherToRetrieveTheSecret: 'パスワードを忘れた？',
	networkAnomaly: 'ネットワークでエラーが発生しました',
	selectCountryAnd: '国・地域を選択',
	complete: '完了',
	appliedForUnlocking: '申請済み',
	iGotIt: 'わかりました',
	didYouForgetToFace: '一時パスワードを忘れましたか？',
	sendEmail:
		'{val1} にメールを送信しました。メール内のURLにアクセスするか、メール内の認証コードを下の空欄に入力してください。',
};
