import {
	apiAuthenticatorConfirmPost,
	apiAuthenticatorSelectPost,
	apiAuthenticatorSendPost,
	apiOscarVerifyBindSendLinkPost,
} from '@/api';
import { OSCAR_DISPLAY_PHONE } from '@/const';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

export const oscarVerify = createModel<RootModel>()({
	state: {}, // initial state
	reducers: {},
	effects: (dispatch) => ({
		async sendVerifyLink({ target, type, next }: { target: string; type: string; next?: boolean }) {
			return apiOscarVerifyBindSendLinkPost({ target, type: Number(type) }).then((res) => {
				next && dispatch.next.nextPage({ res });
				return res;
			});
		},
		async confirm({
			credential,
			handlerType,
			next,
			credentialPublicKey,
		}: {
			credential: string;
			handlerType: number;
			credentialPublicKey?: string;
			next?: boolean;
		}) {
			return apiAuthenticatorConfirmPost({ credential, handlerType, credentialPublicKey }).then(
				(res) => {
					next && dispatch.next.nextPage({ res });
					return res;
				}
			);
		},
		async goSetting(type: number) {
			return apiAuthenticatorSelectPost({ selectType: 2, authenticatorType: type }).then((res) => {
				dispatch.next.nextPage({ res });
				return res;
			});
		},
		async goVerify({ type, methodType }: { type: number; methodType?: number }) {
			return apiAuthenticatorSelectPost({
				selectType: 1,
				authenticatorType: type,
				authenticatorMethodType: methodType,
			}).then((res) => {
				localStorage.setItem(OSCAR_DISPLAY_PHONE, res.next?.displayPhone || '');
				dispatch.next.nextPage({ res });
				return res;
			});
		},
		async sendCode({
			handlerType,
			phone,
			email,
		}: {
			handlerType: number;
			phone?: string;
			email?: string;
		}) {
			return apiAuthenticatorSendPost({ handlerType, phone, email }).then((res) => {
				dispatch.next.nextPage({ res });
				return res;
			});
		},
	}),
});
