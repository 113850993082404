import React from 'react';
import ActionBar from '../actionBar';
import s from './pc.less';

type Props = {
	children: React.ReactNode | React.ReactNode[];
	bottomNode?: React.ReactNode;
};

export default function PageBoxPc({ children, bottomNode }: Props) {
	return (
		<>
			<div className={`${s.box}  ${s.isPc}`}>{children}</div>
			<ActionBar />
			{bottomNode}
		</>
	);
}
