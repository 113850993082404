// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.ttf?t=1664246736521", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'iconfont'; /* Project id  */\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.iconfont {\n\tfont-family: 'iconfont' !important;\n\tfont-size: 1em;\n\tfont-style: normal;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n.icon-checkgou:before {\n\tcontent: '\\e6f2';\n}\n", "",{"version":3,"sources":["webpack://./src/assets/styles/iconfont/iconfont.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB,EAAE,gBAAgB;CACzC,+DAA2D;AAC5D;;AAEA;CACC,kCAAkC;CAClC,cAAc;CACd,kBAAkB;CAClB,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":["@font-face {\n\tfont-family: 'iconfont'; /* Project id  */\n\tsrc: url('iconfont.ttf?t=1664246736521') format('truetype');\n}\n\n.iconfont {\n\tfont-family: 'iconfont' !important;\n\tfont-size: 1em;\n\tfont-style: normal;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n.icon-checkgou:before {\n\tcontent: '\\e6f2';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
