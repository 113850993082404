/**
 * 用a标签打开url
 * @param url
 * @param option '_blank'个别默认webview或者做了url拦截的webview会无法打开，建议用'_self‘方式打开
 */
export const openUrl = (url: string, option = '_self') => {
	const a = document.createElement('a');
	a.setAttribute('href', url);
	a.setAttribute('target', option);
	a.click();
};

/**
 * 尝试用新窗口的方式打开url，如果失败，改为当前标签打开
 */
export const openUrlWithNewWindow = (url: string) => {
	const windowReference = window.open(url, '_blank');
	if (windowReference) {
		console.warn('WebView允许了新窗口的打开');
	} else {
		console.warn('WebView阻止了新窗口的打开', windowReference);
		// 用比较安全的a标签当前页打开
		openUrl(url);
	}
};

/**
 * 打开新页面时，添加来源url
 */
export const openUrlWithFrom = (url: string) => {
	const fromUrl = `${window.location.origin}/login`;
	const newUrl = new URL(url);
	newUrl.searchParams.set('from', fromUrl);
	openUrlWithNewWindow(newUrl.toString());
};

/**
 * 尝试用新标签打开页面，如果不支持新标签打开，则添加来源用当前页打开
 */
export const safeOpenUrl = (url: string) => {
	const windowReference = window.open(url, '_blank');
	if (windowReference) {
		console.warn('WebView允许了新窗口的打开');
	} else {
		console.warn('WebView阻止了新窗口的打开', windowReference);
		// 用比较安全的a标签当前页打开
		const fromUrl = `${window.location.origin}/login`;
		const newUrl = new URL(url);
		newUrl.searchParams.set('from', fromUrl);
		openUrl(newUrl.toString());
	}
};
