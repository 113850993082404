export default {
	announcement1:
		"Ngpush sera en maintenance le lundi 15 janvier 2024 de 11 heure à 11 heure 30 (heure de Chine). Les fonctions Oscar Verify de notifications, celles de liaison et d'authentification ne seront pas disponibles. ",
	continueWithOp: 'Avec OpenID',
	continueWithOs: 'Via Oscar',
	sendMeEmail: 'Envoyer un E-mail',
	clickSendMeEmail: 'Cliquez sur {val1} pour obtenir un code de vérification.',
	getVerifyEmail: "Envoi d'un mail de vérification.",
	phoneSettingTips: 'Attention',
	readAndAgree2: "Veuillez d'abord lire et accepter {val1}",
	seviceAgreement: 'Accord de Service',
	hasActivatedYour:
		'Votre compte {val1} a été activé. Pour utiliser Dashboard, veuillez configurer vos méthodes de validation.',
	inYourFrequentlyUsedMobile:
		"Une fois que vous avez installé l'application Oscar Verify sur votre appareil mobile et lié votre compte, vous pouvez effectuer une vérification de sécurité en utilisant les codes générés par l'application ou par des notifications.",
	enableSecurityVerification: 'Activer la méthode de vérification sécurisée.',
	afterActivationYouCan:
		'Une fois activée, vous pouvez utiliser la méthode de vérification de sécurité correspondante pour vous connecter à votre compte Oscar.',
	enableOsc: 'Activer la vérification Oscar Verify.',
	step1Download: "Première étape, télécharger l'application.",
	youNeedToUse:
		"Vous devez scanner le code QR à l'aide de votre appareil mobile pour télécharger l'application Oscar Verify.",
	oscarVerify: "Le package d'installation Oscar Verify.",
	downloadedApp: 'Téléchargé, commencer la liaison.',
	step2Binding: 'Deuxième étape, lier le compte.',
	selectInTheApplication:
		'Dans l\'application, sélectionnez "Ajouter un compte" - "Scanner maintenant", scannez le code QR ci-dessous pour lier le compte à l\'appareil actuel. Après la liaison réussie, la page se rafraîchira automatiquement.',
	bindQrCode: 'Lier le code QR',
	switchToOtherBindings: 'Changer de méthode de liaison',
	notDownloadedYet: 'Application non téléchargée pour le moment？',
	scanCodeToDownloadO_2: 'Scannez le code pour télécharger Oscar Verify',
	viewDownload2: 'Voir le code QR de téléchargement',
	throughMessageHorizontal:
		"Effectuer une vérification de sécurité rapide via une bannière de message ou une notification dans l'application",
	withinTheApplication:
		"Effectuer une vérification de sécurité en utilisant un code de vérification généré dynamiquement dans l'application",
	oscar_2: 'Oscar Verify propose deux méthodes de vérification de sécurité',
	enableMobileVerification: 'Activer la vérification par téléphone portable',
	logInToTheApp: "Se connecter à l'application",
	unableToRetrieve: 'Impossible de récupérer',
	enableSecurityVerification_2: 'Activer la vérification de sécurité',
	startCertification: "Commencer l'authentification",
	validateEmailTips:
		'Un code de validation a été envoyé à {val1}. Veuillez vérifier votre boîte de réception et entrer le code ci-dessous pour continuer.',
	validateEmailTips2:
		"Une fois que vous aurez cliqué sur le bouton d'envoi, nous enverrons un e-mail de validation à {val1}.",
	verifyEmailSendTips:
		"Nous avons envoyé un e-mail de validation à {val1}. Veuillez vérifier votre boîte de réception et cliquer sur le lien de validation dans l'e-mail ou {val2} pour finaliser la validation.",
	emailVerification: 'Validation par e-mail',
	sendingAnEmail: 'Envoyer un e-mail',
	pleaseResend: 'Pour accéder à la page, veuillez suivre le lien envoyé par e-mail.',
	setUpEmailVerification: 'Configurer une adresse e-mail comme méthode de validation',
	pleaseVerifyInTheEmail:
		'Veuillez entrer le code ci-dessous dans la page de confirmation reçue par e-mail.',
	ifYouDontHave:
		"Si vous n'avez pas demandé ce code de validation, vous pouvez simplement ignorer ce message.",
	verifyThatTheEmailHasBeenSent: 'Un e-mail de validation a été envoyé avec succès.',
	aiden: 'Entrez le code de validation',
	byBound_2:
		"Afin de recevoir le code de validation, veuillez envoyer un e-mail à l'adresse associée à votre compte.",
	byBindingO:
		"Pour recevoir des notifications d'authentification, veuillez associer un appareil mobile à Oscar Verify.",
	throughOsc_2: 'Obtenez le code de validation via Oscar Verify.',
	enable: 'Activer',
	toEnsureTheAccount:
		'Pour renforcer la sécurité de votre compte, veuillez ajouter votre adresse e-mail personnelle comme adresse de validation.',
	pleaseEnterYour_2: 'Veuillez entrer votre adresse e-mail.',
	emailVerification_2: 'E-mail de validation',
	bindEmailAccount:
		'Veuillez configurer une adresse e-mail afin de recevoir le code de validation.',
	smsVerification: 'Code SMS',
	bindCommonlyUsedHands:
		'Configurer un téléphone portable pour recevoir des codes de validation par SMS.',
	onMobileDevices:
		'Pour recevoir des codes de validation ou des notifications poussées, installez et configurez Oscar Verify sur votre appareil mobile.',
	sent: "Vous avez atteint la limite d'envoi.",
	verificationEmailSent:
		"Vous avez atteint la limite d'envoi d'e-mails. Veuillez vérifier votre adresse e-mail ou essayer une autre méthode de validation.",
	verifySmsSending:
		"Vous avez atteint la limite d'envoi des messages. Veuillez vérifier votre numéro de cellulaire ou essayer une autre méthode de validation.",
	linkExpired: 'Le lien a expiré.',
	thisEmail:
		'Ce lien a expiré. Veuillez retourner à la page de connexion et cliquer sur le bouton Renvoyer pour en recevoir un nouveau.',
	cancel: 'Annuler',
	nextStep2: 'Suivant',
	Recover: 'Retrouver un mot de passe oublié',
	notFound: 'Aucun résultat trouvé',
	langSetting: 'Paramètres de langue',
	languageSwitching: 'Changement de langue',
	searchPlaceholder: 'Rechercher',
	noResultFound: 'Aucun résultat trouvé',
	termsOfService: "conditions d'utilisation du service",
	loginTips: 'Avant de continuer',
	readAndAgree: 'Veuillez lire et accepter {val1}.',
	iAgree: "J'accepte les {val1}.",
	Agree: 'Accepter',
	NoPermission: 'Aucune permission',
	UnableToSign: 'Impossible de se connecter',
	xiaZaiYingYong: 'Télécharger',
	bangDingSheBei: 'Configurer',
	shangYiBu: 'Précédent',
	unableActivate: "Nous n'avons pas pu activer votre compte {val1}.",
	exceedActivateTimes:
		"Vous avez effectué trop de demandes d'activation pour le compte {val1} aujourd'hui. Veuillez attendre la fin du décompte avant d'en effectuer de nouvelles.",
	activateEmailSendTips:
		'Un e-mail a été envoyé à {val1}. Veuillez ouvrir le lien contenu dans cet e-mail pour activer votre compte.',
	validateActivateEmailTips:
		"Nous avons envoyé un e-mail à {val1}. Veuillez entrer le code de validation de l'e-mail ci-dessous pour terminer la validation.",
	exceptionReminder: 'Erreur',
	notAtPresent: "L'auto-activation n'est pas disponible.",
	unableToActivate: "L'activation a échoué.",
	activationExceeded: "Trop de tentatives d'activation",
	theActivationMessageHasBeen: "E-mail d'activation envoyé",
	backInHelp: "Centre d'aide",
	returnToAccountVerification: 'Validation du compte',
	verificationCodeHasBeenSent: 'Code de validation envoyé',
	accountActivation: 'Activer',
	activateYourO: 'Activer votre compte',
	pleaseEnterTheRequired: 'Veuillez entrer le nom du compte à activer.',
	getVerificationCode: 'Obtenir un code de validation',
	helpCenter: "Centre d'aide",
	queRenDengLu: 'Confirmer votre connexion',
	queRenYiOS: 'Se connecter avec un compte Oscar',
	nonOverseasEmployees: 'Disponible pour les utilisateurs nationaux',
	signInWithOpenid: 'Se connecter avec Corp',
	accountNotActivated: 'Compte non activé ? {val1}',
	activated: 'Activer',
	currentOsc:
		"Actuellement, seuls les utilisateurs ayant un compte de messagerie OC (@oc.netease.com) peuvent recevoir l'e-mail d'activation pour activer leur compte Oscar.",
	pageLoadFailedAndRetry: 'Échec du chargement de la page.\r\nVeuillez réessayer.',
	reload: 'Rafraîchir la page',
	pleaseSelect: 'Veuillez sélectionner',
	afterClickingTheButton:
		"Le téléchargement débutera automatiquement après avoir cliqué sur le bouton. Veuillez suivre la progression du téléchargement sur votre écran d'accueil.",
	clickOnTheUpperRightCorner: 'Cliquer sur',
	selectToBrowse: 'Sélectionnez Ouvrir dans le navigateur.',
	youHaveLoggedOut: 'Vous avez été déconnecté.',
	yourAccountHas: 'Votre compte a été déconnecté de cette application.',
	minute: 'minute',
	announcement:
		"Pour améliorer la sécurité et la stabilité du système, l'équipe d'Oscar effectuera une mise à niveau du système du {val1} au {val2}. Pendant cette période, vous pourrez toujours vous connecter à votre compte, mais les fonctions d'activation de compte et de réinitialisation de mot de passe ne seront pas disponibles.",
	thanks: 'Si vous avez des questions, veuillez contacter oscarfeedback@oc.netease.com.',
	optional: '(Facultatif)',
	downloadNow: 'Télécharger',
	backToPasswordVerification: 'Valider avec le mot de passe',
	otherVerifiers: 'Autres méthodes de validation',
	returnToSafetyInspection: 'Paramètres de validation',
	backToLogin: 'Page de connexion',
	returnToWorkbench: 'Dashboard',
	resend: 'Renvoyer',
	nextStep: 'Suivant',
	rememberAccountNumber: 'Se souvenir de moi',
	pleaseEnterYour: 'Adresse e-mail OC ou celle du studio',
	signIn: 'Se connecter',
	theAccountFormatIs: 'Le format du compte est incorrect. Veuillez réessayer.',
	emailFormatIsError: 'Veuillez entrer une adresse courriel valide.',
	logInToOsc: 'Se connecter à Oscar Verify ',
	logInToOsc2: 'Se connecter à Oscar Dashboard',
	logInToOsc3: "Se connecter en tant qu'administrateur",
	selectSafetyInspection: 'Sélectionner une méthode de validation',
	toVerify: 'Valider',
	throughOsc: 'Depuis Oscar Verify',
	verificationCode: 'Code de validation',
	pushNotification: 'Notification poussée',
	byBound: 'Recevoir sur le numéro de téléphone lié à votre compte',
	smsCode: 'Obtenir un code SMS',
	autoSendPush: 'Envoi automatique des notifications',
	resendPush: 'Réenvoyer une notification poussée',
	pushSent: 'Notification poussée envoyée',
	noPushReceived:
		"Veuillez accéder à Oscar Verify pour voir la notification poussée sur votre appareil. La notification peut être réenvoyée si elle n'a pas été reçue.",
	pushNotificationInspection: 'Valider avec la notification poussée',
	pushExpired: 'La notification poussée est expirée',
	youJustDeclined: 'Vous avez refusé cette connexion.',
	verificationNotReceived: "Vous n'avez pas reçu le code ?",
	pleaseEnterBelow:
		'Veuillez saisir le code de validation envoyé à {val1} pour terminer la validation.',
	smsCodeVerification: 'Valider avec un code SMS',
	verifying: 'Validation en cours',
	fromOsc:
		"Veuillez entrer le code de validation que vous avez reçu de l'application Oscar Verify pour compléter la validation.",
	verificationCodeVerification: 'Valider avec un code de validation',
	toSet: 'Configurer',
	bindMobilePhoneTo: 'Envoyer un code de validation à votre téléphone',
	mobilePhoneVerification: 'Valider sur le téléphone',
	oscar:
		"Oscar Verify est une application d'authentification qui peut être installée sur votre téléphone pour la validation de la connexion.",
	afterSettingYouCan:
		'Vous pouvez utiliser les méthodes suivantes pour valider votre identité et vous connecter à votre compte Oscar.',
	setSafetyInspection: 'Configuration des méthodes de validation',
	sendLink: 'Envoyer le lien',
	pleaseEnterYour2: 'Veuillez entrer votre adresse e-mail.',
	throughMailChain:
		'Complétez la configuration via le lien envoyé à votre adresse e-mail pour pouvoir accéder à votre courrier électronique sur les appareils mobiles.',
	setOsc: 'Configurer Oscar Verify',
	unableToScan: 'Impossible de numériser ?',
	scanTheLowerTwo: 'Numérisez le code QR ci-dessous pour ajouter votre compte.',
	openTheAppAnd:
		"Ouvrez l'application et sélectionnez Numériser maintenant en suivant les instructions.",
	scanInstallationPackage: "Obtenir l'installateur",
	scanCodeToDownloadO: 'Numérisez le code QR pour télécharger Oscar Verify',
	downloadOsc: 'Télécharger Oscar Verify',
	scanSucceeded: 'Code numérisé avec succès',
	clickRefresh: 'Rafraîchir',
	qrCodeLost: 'Code QR expiré',
	setLater: 'Configurer plus tard',
	afterSettingYouCan2:
		'Vous pouvez utiliser les méthodes de validation pour valider votre identité et vous connecter à votre compte Oscar.',
	otherSafetyInspection: 'Autres méthodes de validation',
	sendVerificationCode: 'Envoyer un code de validation',
	pleaseEnterYour3: 'Entrez ici votre numéro de cellulaire.',
	setMobilePhoneVerification: 'Configurer la validation par téléphone',
	china: '+86 (Chine continentale)',
	noLinkReceived: "Vous n'avez pas reçu le lien ?",
	pleaseUseMobileDevice:
		"Veuillez ouvrir le lien envoyé à {val1} et suivre les instructions à l'écran pour terminer la validation.",
	openApp: "Ouvrir l'application",
	shouldBeOpened:
		"Ouvrez l'application pour terminer la validation. Si vous n'avez pas installé Oscar Verify sur votre appareil, veuillez cliquer sur le bouton ci-dessous pour télécharger l'installateur.",
	oscar2:
		"Oscar Verify est une application d'authentification de sécurité à deux facteurs, vous pouvez l'utiliser vous connecter à Oscar.",
	applyForUnlocking: 'Déverrouiller',
	hopesItCanBeSolvedImmediately: 'Déverrouiller votre compte immédiatement ?',
	requestSent: 'Votre demande a été envoyée.',
	systemOccurred:
		"Erreur inconnue. Veuillez rechercher IT Helpdesk sur POPO / Teams / Slack si vous avez besoin d'aide.",
	unknownError: 'Erreur inconnue',
	searchIsNotSupportedTemporarily:
		'Votre compte {val1} ne prend pas en charge la récupération de mot de passe. Si vous avez des problèmes, veuillez rechercher IT Helpdesk sur POPO / Teams / Slack.',
	yourAccount: 'Votre compte',
	unableToRetrieveTheSecret: 'Échec de récupération de mot de passe',
	refreshPage: 'Veuillez rafraîchir la page et réessayer.',
	currentSystemRing: "Une erreur est survenue dans l'environnement du système. Veuillez réessayer.",
	systemAbnormality: 'Erreur',
	youHaveJustTaken:
		'Vous avez annulé cette demande de récupération de mot de passe, veuillez retourner à la page de connexion.',
	cancelRetrievingTheSecret: 'Annuler la demande de récupération de mot de passe',
	youAreTryingToUse:
		'Le lien est invalide. Veuillez retourner à la page de connexion pour obtenir un nouveau lien.',
	linkHasExpired: 'Lien invalide',
	ifYouHave:
		"Cela pourrait être dû au fait que vous avez réinitialisé votre mot de passe ou que le lien que vous avez utilisé est invalide. Pour obtenir de l'aide, veuillez rechercher IT Helpdesk sur POPO/Teams/Slack.",
	ifYouHaveStimulated:
		"Cela pourrait être dû au fait que vous avez activé Oscar Verify ou que vous avez essayé d'utiliser un lien invalide. Veuillez retourner à la page de connexion et envoyer un autre lien.",
	ifYouHaveStimulated2:
		"Cela pourrait être dû au fait que vous avez activé votre compte ou que vous avez essayé d'utiliser un lien invalide. Pour obtenir de l'aide, veuillez rechercher IT Helpdesk sur POPO/Teams/Slack.",
	unlockingTime: 'Déverrouillé à :',
	abnormalRegistration:
		'Le système a détecté une connexion inhabituelle à votre compte et a verrouillé votre compte. Vous devez déverrouiller votre compte avant de vous connecter. ',
	accountLocked: 'Le compte est verrouillé',
	invalid:
		"L'action actuelle n'est pas disponible en raison de la désactivation de votre compte. Veuillez contacter votre administrateur pour obtenir de l'aide supplémentaire.",
	accountInvalid: 'Compte désactivé',
	dueToBeingInNon:
		"Pour cause d'inactivité, vous avez été automatiquement déconnecté. Veuillez retourner à la page de connexion.",
	sessionTimeOut: 'La session a expiré.',
	noMailReceived: 'Courriel non reçu ?',
	retrievePassword: 'Retrouver le mot de passe',
	itsMe: "Oui, c'était moi.",
	noNotMe: "Non, ce n'était pas moi.",
	didYouJust: 'Avez-vous essayé de vous connecter ?',
	youAreAlreadyInAnother: 'La récupération de mot de passe a été poursuivie sur une autre page.',
	pageIsBeingCalibrated: 'Validation en cours, veuillez patienter.',
	determine: 'OK',
	pleaseEnterAgain: 'Confirmez le nouveau mot de passe',
	andTheFirstLoss: 'Entrez à nouveau le mot de passe.',
	pleaseEnterANewSecret: 'Entrez le nouveau mot de passe.',
	setNewPassword: 'Réinitailiser le mot de passe',
	notTheSameAsTheAccountName: "Le mot de passe et le nom d'utilisateur doivent être différents.",
	includingCapitalLetters:
		'doit contenir au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre. ',
	atLeastCharacters: 'doit contenir au moins 8 caractères (20 au maximum)',
	operationSucceeded: 'Terminé',
	sPasswordHasBeenReset:
		'Le mot de passe de votre compte {val1} a été réinitialisé. Pour utiliser Dashboarf, veuillez valider à nouveau votre identité.',
	resetSucceeded: 'Réinitialisé avec succès',
	refreshUnlocked:
		'Le compte {val1} a été déverrouillé. Veuillez rafraîchir la page ou cliquer sur Page de connexion pour réessayer.',
	accountUnlocked: 'Compte déverrouillé',
	activationSucceeded: 'Activé avec succès',
	pleaseEnterTemporary: 'Veuillez entrer le mot de passe temporaire.',
	passwordVerification: 'Valider avec votre mot de passe',
	forgotPassword: 'Mot de passe oublié ?',
	verification: 'Valider',
	inCaseOfAnyDifficulty:
		"Pour obtenir de l'aide supplémentaire, veuillez rechercher IT Helpdesk sur POPO/Teams/Slack.",
	pleaseEnterTheAccountNumber: 'Veuillez entrer le mot de passe.',
	ifYouForget: 'Si vous avez oublié votre mot de passe, vous pouvez récupérer votre mot de passe.',
	continuous:
		'{val1} tentatives consécutives infructueuses. Après {val2} tentatives infructueuses, le compte sera verrouillé pendant {val3} heures.',
	tooManyErrors: 'Trop de tentatives infructueuses',
	hoursIfYou2:
		"Vous avez oublié le mot de passe temporaire ? Veuillez vérifier l'e-mail d'activation.",
	continueTyping: 'Annuler',
	whetherToRetrieveTheSecret: 'Retrouver le mot de passe ?',
	networkAnomaly: "Une erreur de réseau s'est produite.",
	selectCountryAnd: 'Choisissez le pays et la région',
	complete: 'Terminé',
	appliedForUnlocking: 'Demande envoyée',
	iGotIt: "J'ai compris.",
	didYouForgetToFace: 'Vous avez oublié le mot de passe temporaire ?',
	sendEmail:
		"Un e-mail a été envoyé à {val1}. Pour finaliser la validation, veuillez ouvrir le lien dans l'e-mail ou saisir le code de validation ci-dessous.",
};
