import openHelpCenter from '@/utils/openHelpCenter';
import { HelpCenter } from '@bedrock/icons-react';
import classNames from 'classnames';
import { useCallback } from 'react';
import styles from './h5.less';

interface Props {}

const HelpBtnH5 = (props: Props) => {
	// const [visible, setVisible] = useState(true);
	//移动端小屏，隐藏帮助中心浮窗
	let hidden = document.documentElement.clientHeight < 550;

	const onClick = useCallback(() => {
		openHelpCenter();
	}, []);

	if (hidden) {
		return null;
	}

	return (
		<div
			className={classNames('flex items-center justify-center', styles['help-btn'])}
			onClick={onClick}
		>
			<HelpCenter />
		</div>
	);
};

export default HelpBtnH5;
