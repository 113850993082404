export default {
	announcement1:
		'Ngpush计划于2024年1月15日（周一）11:00 - 11:30 (GMT+08) 期间进行服务维护，Oscar verify的推送和绑定认证服务将受影响暂停使用。给大家带来不便敬请谅解',
	continueWithOp: '使用 OpenID 登录',
	continueWithOs: '使用 Oscar 登录',
	sendMeEmail: '发送电子邮件',
	clickSendMeEmail: '点击“{val1}”，获取验证码',
	getVerifyEmail: '获取验证电子邮件',
	phoneSettingTips: '提示',
	readAndAgree2: '请先阅读并同意 {val1}',
	seviceAgreement: '服务协议',
	hasActivatedYour: '您的帐号{val1}已激活，您需要进行安全验证方式设置才能使用工作台。',
	inYourFrequentlyUsedMobile:
		'在您常用移动设备上安装 Oscar Verify 应用程序且绑定帐号后，可通过应用生成的验证码或通知，完成安全验证',
	enableSecurityVerification: '启用安全验证方式',
	afterActivationYouCan: '启用后，您可使用相应方式进行安全验证，登录使用您的 Oscar 帐号。',
	enableOsc: '启用 Oscar Verify 验证',
	step1Download: '第一步，下载应用',
	youNeedToUse: '您需要使用移动设备扫码，下载 Oscar Verify 应用',
	oscarVerify: 'Oscar Verify 安装包',
	downloadedApp: '已下载应用，开始绑定',
	step2Binding: '第二步，绑定帐号',
	selectInTheApplication:
		'在应用中选择“添加帐号”-“立即扫描”，扫描下图中的二维码，将帐号绑定至当前设备。绑定成功后，页面将自动刷新。',
	bindQrCode: '绑定二维码',
	switchToOtherBindings: '切换其他绑定方式',
	notDownloadedYet: '暂未下载应用？',
	scanCodeToDownloadO_2: '扫码下载 Oscar Verify',
	viewDownload2: '查看下载二维码',
	throughMessageHorizontal: '通过消息横幅或应用内消息通知，进行快速的安全验证',
	withinTheApplication: '使用应用内动态生成的验证码，进行安全验证',
	oscar_2: 'Oscar Verify 应用将提供两种安全验证方式：',
	enableMobileVerification: '启用手机验证',
	logInToTheApp: '登录至应用',
	unableToRetrieve: '无法找回',
	enableSecurityVerification_2: '启用安全验证',
	startCertification: '开始认证',
	validateEmailTips: '已发送邮件至 {val1}，请在下方输入邮件中的验证码完成验证。',
	validateEmailTips2: '点击发送按钮后，将会向{val1}发送验证邮件',
	verifyEmailSendTips: '已发送邮件至{val1}, 请检查您的邮件并点击邮件中的验证链接或者{val2}完成验证',
	emailVerification: '邮件验证',
	sendingAnEmail: '发送邮件',
	pleaseResend: '请重新在邮件访问链接',
	setUpEmailVerification: '设置邮件验证',
	pleaseVerifyInTheEmail: '请在邮件验证页面输入以下验证码',
	ifYouDontHave: '如果您没有请求此验证码，则可以忽略该消息',
	verifyThatTheEmailHasBeenSent: '验证邮件已发送',
	aiden: '手动输入验证码',
	byBound_2: '通过已绑定的邮箱发送验证码',
	byBindingO: '通过绑定 Oscar verify 的移动设备接收认证通知',
	throughOsc_2: '通过 Oscar verify 应用获取验证码',
	enable: '启用',
	toEnsureTheAccount: '为确保账号安全，请将个人邮箱作为验证邮箱',
	pleaseEnterYour_2: '请输入您的邮箱',
	emailVerification_2: '邮箱验证',
	bindEmailAccount: '绑定邮箱账号后，可通过邮件接收安全验证码',
	smsVerification: '短信验证',
	bindCommonlyUsedHands: '绑定常用手机号后，可通过短信接收安全验证',
	onMobileDevices:
		'在移动设备上安装 Oscar Verify 应用程序且绑定帐号后，可通过应用生成的验证码或通知，完成安全验证',
	sent: '发送次数已达上限',
	verificationEmailSent: '验证邮件发送次数已达上限，请检查您的邮箱或选择其他验证方式',
	verifySmsSending: '验证短信发送次数已达上限，请检查您的手机号或选择其他验证方式',
	linkExpired: '链接过期',
	thisEmail: '此电子邮件链接已过期，若要重新发送，请返回登录并重试',
	cancel: '取消',
	nextStep2: '下一步',
	Recover: '找回密码',
	notFound: '暂未搜索到内容',
	langSetting: '语言设置',
	languageSwitching: '语言切换中...',
	searchPlaceholder: '搜索',
	noResultFound: '暂未搜索到内容',
	termsOfService: '服务协议',
	loginTips: '登录提示',
	readAndAgree: '请先阅读并同意 {val1}',
	iAgree: '已阅读并同意 {val1}',
	Agree: '同意',
	NoPermission: '暂无权限',
	UnableToSign: '您的账号暂时无法登录',
	xiaZaiYingYong: '下载应用',
	bangDingSheBei: '绑定设备',
	shangYiBu: '上一步',
	unableActivate: '您的帐号{val1}暂时无法激活',
	exceedActivateTimes: '您的帐号{val1}今日激活次数过多，请倒计时结束后重试：',
	activateEmailSendTips: '已发送邮件至 {val1}，请打开邮件中链接进行帐号激活',
	validateActivateEmailTips: '已发送邮件至{val1}，在下方输入邮件中的验证码完成验证。',
	exceptionReminder: '异常提醒',
	notAtPresent: '目前暂时不提供账号自助激活服务',
	unableToActivate: '无法激活',
	activationExceeded: '已超过激活次数',
	theActivationMessageHasBeen: '激活邮件已发送',
	backInHelp: '返回帮助中心',
	returnToAccountVerification: '返回帐号验证',
	verificationCodeHasBeenSent: '验证码已发送',
	accountActivation: '帐号激活',
	activateYourO: '激活您的 Oscar 帐号',
	pleaseEnterTheRequired: '请输入需要激活的帐号',
	getVerificationCode: '获取验证码',
	helpCenter: '帮助中心',
	queRenDengLu: '确认登录',
	queRenYiOS: '确认以 Oscar 账号登录',
	nonOverseasEmployees: '非海外员工登录',
	signInWithOpenid: 'Corp邮箱帐号登录',
	accountNotActivated: '帐号暂未激活？{val1}',
	activated: '立即激活',
	currentOsc:
		'目前Oscar帐号仅针对oc邮箱（oc@netese.com）后缀的邮箱账号进行激活，激活邮件将发送至您的oc邮箱',
	pageLoadFailedAndRetry: '页面加载失败\n请重试',
	reload: '重新加载',
	pleaseSelect: '请选择',
	afterClickingTheButton: '点击按钮后自动进行下载，请前往桌面查看',
	clickOnTheUpperRightCorner: '点击右上角的',
	selectToBrowse: '选择在浏览器中打开',
	youHaveLoggedOut: '您已退出登录',
	yourAccountHas: '您的账号已成功退出应用',
	minute: '分钟',
	announcement:
		'为提升系统的安全性和稳定性，Oscar团队将于{val1}期间进行系统升级，预计持续{val2}。在此期间，您仍可登录帐号，但【帐号激活】和【密码重置】功能将暂停使用。',
	thanks:
		'感谢您一直以来对我们的支持和信任，如有任何疑问或需要帮助，请随时联系我们oscarfeedback@oc.netease.com。',
	optional: '(选填)',
	downloadNow: '立即下载',
	backToPasswordVerification: '返回密码验证',
	otherVerifiers: '其他验证方式',
	returnToSafetyInspection: '返回安全验证设置',
	backToLogin: '返回登录',
	returnToWorkbench: '返回工作台',
	resend: '重新发送',
	nextStep: '下一步',
	rememberAccountNumber: '记住帐号',
	pleaseEnterYour: '请输入您的oc或工作室邮箱',
	signIn: '登录',
	theAccountFormatIs: '帐号格式错误，请重新输入',
	emailFormatIsError: '电子邮箱格式有误，请重新输入',
	logInToOsc: '登录 Oscar 身份验证器',
	logInToOsc2: '登录 Oscar 工作台',
	logInToOsc3: '登录 Oscar 管理员平台',
	selectSafetyInspection: '选择安全验证方式',
	toVerify: '去验证',
	throughOsc: '通过 Oscar verify 获取',
	verificationCode: 'Oscar verify 验证码',
	pushNotification: 'Oscar verify 推送通知',
	byBound: '通过已绑定手机获取',
	smsCode: '短信代码',
	autoSendPush: '自动发送推送通知',
	resendPush: '重新发送推送通知',
	pushSent: '已发送推送通知',
	noPushReceived: '请您在移动设备上打开 Oscar Verify 查看通知，仍未收到可尝试重新发送',
	pushNotificationInspection: '推送通知验证',
	pushExpired: '推送已过期',
	youJustDeclined: '您刚刚拒绝了此次登录',
	verificationNotReceived: '未收到验证码？',
	pleaseEnterBelow: '已发送验证码至{val1}，请在下方输入完成验证。',
	smsCodeVerification: '短信代码验证',
	verifying: '验证中',
	fromOsc: '请从 Oscar Verify 应用程序中获取验证码，并在下方输入完成验证。',
	verificationCodeVerification: '验证码验证',
	toSet: '去设置',
	bindMobilePhoneTo: '向绑定手机发送验证码，以完成身份验证',
	mobilePhoneVerification: '手机验证',
	oscar: 'Oscar verify是一款身份验证应用程序，您可安装在手机上，用以登录时的验证',
	afterSettingYouCan: '设置后，您可使用相应方式通过身份验证，登录您的 Oscar 帐号。',
	setSafetyInspection: '设置安全验证方式',
	sendLink: '发送链接',
	pleaseEnterYour2: '请输入您的电子邮箱',
	throughMailChain: '通过邮件链接完成设置，请确保您的邮箱可以在移动设备上使用。',
	setOsc: '设置 Oscar Verify',
	unableToScan: '无法扫描？',
	scanTheLowerTwo: '扫描下方二维码添加您的帐号',
	openTheAppAnd: '打开应用并根据页面指引选择“立即扫描”',
	scanInstallationPackage: '获取安装包',
	scanCodeToDownloadO: '扫码下载 Oscar Verify 应用',
	downloadOsc: '下载 Oscar verify 应用',
	scanSucceeded: '扫描成功',
	clickRefresh: '点击刷新',
	qrCodeLost: '二维码已失效',
	setLater: '以后设置',
	afterSettingYouCan2: '设置后，您可使用安全验证方式通过身份认证，登录您的 Oscar 帐号。',
	otherSafetyInspection: '其他安全验证方式',
	sendVerificationCode: '发送验证码',
	pleaseEnterYour3: '请输入您的手机号',
	setMobilePhoneVerification: '设置手机验证',
	china: '+86 (中国)',
	noLinkReceived: '未收到链接？',
	pleaseUseMobileDevice: '已发送链接至{val1}，请在移动设备上打开链接并按照页面指引完成验证。',
	openApp: '打开应用',
	shouldBeOpened:
		'需要打开应用完成后续操作。如果您的设备上没有安装Oscar Verify, 请点击下方按钮下载。',
	oscar2:
		'Oscar Verify\n是一个轻量的应用，用于组织帐号登录时的双因素认证，您可以通过它进行安全的身份验证并登录 Oscar\n\t\t\t\t帐号。',
	applyForUnlocking: '申请解锁',
	hopesItCanBeSolvedImmediately: '希望立即解锁？',
	requestSent: '已发送申请',
	systemOccurred:
		'系统发生了未知的错误，如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	unknownError: '未知错误',
	searchIsNotSupportedTemporarily:
		'您的帐号{val1}暂不支持找回密码。如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	yourAccount: '您的帐号',
	unableToRetrieveTheSecret: '无法找回密码',
	refreshPage: '刷新页面',
	currentSystemRing: '当前系统环境存在异常，如要继续请重试。',
	systemAbnormality: '系统异常',
	youHaveJustTaken: '您刚刚已取消了此次密码找回申请，请返回登录。',
	cancelRetrievingTheSecret: '取消找回密码',
	youAreTryingToUse: '您尝试使用的 URL 无效，重新发送请返回登录流程。',
	linkHasExpired: '链接已失效',
	ifYouHave:
		'如果您已重置密码，或您尝试使用的 URL无效，则可能会发生这种情况。如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	ifYouHaveStimulated:
		'如果您已激活 Oscar Verify 设置，或者您尝试使用的 URL 过期，则可能发生这种情况；重新发送请返回登录流程。如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	ifYouHaveStimulated2:
		'如果您已激活帐号，或者您尝试使用的 URL 无效，则可能会发生这种情况。如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	unlockingTime: '解锁时间：',
	abnormalRegistration:
		'您的帐号{val1}存在异常登录行为，已被系统锁定。您可在解锁后继续流程。{val2}',
	accountLocked: '帐号已锁定',
	invalid: '已失效，无法继续当前操作，请与管理员联系以获取进一步帮助。',
	accountInvalid: '帐号失效',
	dueToBeingInNon: '由于处于非活跃状态，您已被注销。请返回登录。',
	sessionTimeOut: '会话超时',
	noMailReceived: '未收到邮件？',
	retrievePassword: '找回密码',
	itsMe: '是，是我',
	noNotMe: '否，不是我',
	didYouJust: '您刚才是否尝试过登录？',
	youAreAlreadyInAnother: '您已在其他页面继续找回密码流程。',
	pageIsBeingCalibrated: '页面正在校验中，请稍后...',
	determine: '确定',
	pleaseEnterAgain: '请再次输入密码',
	andTheFirstLoss: '与第一次输入一致',
	pleaseEnterANewSecret: '请输入新密码',
	setNewPassword: '设置新密码',
	notTheSameAsTheAccountName: '与帐号名不同',
	includingCapitalLetters: '含大/小写字母、数字、特殊字符中三种组合',
	atLeastCharacters: '至少8个字符，最多不超过20个',
	operationSucceeded: '操作成功',
	sPasswordHasBeenReset: '您的帐号{val1}的密码已重置，您需要重新验证身份才能使用工作台。',
	resetSucceeded: '重置成功',
	refreshUnlocked: '您的帐号{val1}已解锁，刷新页面或点击返回登录重新尝试登录。',
	accountUnlocked: '帐号已解锁',
	activationSucceeded: '激活成功',
	pleaseEnterTemporary: '请输入临时密码',
	passwordVerification: '密码验证',
	forgotPassword: '忘记密码？',
	verification: '验证',
	inCaseOfAnyDifficulty: '如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	pleaseEnterTheAccountNumber: '请输入帐号密码',
	ifYouForget: '如果您忘记密码，可尝试找回密码。',
	continuous: '已连续{val1}次输入错误，再错误输入{val2}次后，帐号将被锁定{val3}小时。',
	tooManyErrors: '错误次数过多',
	hoursIfYou2: '如果您忘记临时密码，可以查看激活邮件获取。',
	continueTyping: '继续输入',
	whetherToRetrieveTheSecret: '是否找回密码？',
	networkAnomaly: '网络异常',
	selectCountryAnd: '选择国家和地区',
	complete: '完成',
	appliedForUnlocking: '已申请解锁',
	iGotIt: '我知道了',
	didYouForgetToFace: '是否忘记临时密码？',
	sendEmail: '已发送邮件至 {val1} 请打开邮件中链接，或在下方输入邮件中的验证码完成验证。',
};
