import { getIsMobile } from '@/utils';
import I18N from '@/utils/I18N';
import { Modal as BedrockModal } from '@bedrock/components';
import type { ModalFuncProps, ModalProps } from '@bedrock/components/es/Modal/index';
import s from './index.less';

interface Props extends ModalProps {}

export default function Modal(props: Props) {
	const isMobile = getIsMobile();
	const {
		wrapClassName,
		className,
		/*因为bedrock支持语种有限，文案全都要避免走默认值 */
		cancelText = I18N.auto.cancel,
		okText = I18N.auto.determine,
		...restProps
	} = props;
	return (
		<BedrockModal
			className={`${isMobile ? s.isMobile : s.isPc} ${className || ''}`}
			wrapClassName={`${wrapClassName || ''} ${!isMobile ? s.pcWrapper : ''}`}
			maskClosable={false}
			closable={false}
			cancelText={cancelText}
			okText={okText}
			{...restProps}
		></BedrockModal>
	);
}

interface AlertProps extends ModalFuncProps {
	okText: string;
	cancelText: string;
}

Modal.confirm = function (props: AlertProps) {
	return BedrockModal.confirm({ ...props, className: s.confirm });
};
Modal.warning = function (props: AlertProps) {
	return BedrockModal.warning({ ...props, className: s.warning });
};
Modal.info = BedrockModal.info;
Modal.destroyAll = BedrockModal.destroyAll;

// export default BedrockModal;

// BedrockModal.displayName = 'BedrockModal';
