import { Input as BasicInput } from '@bedrock/components';
import { InputProps } from '@bedrock/components/es/Input/Input';
import { CloseCircleFilled } from '@bedrock/icons-react';
import './index.less';

interface Props extends InputProps {
	/*因为bedrock支持语种有限，文案全都要避免走默认值 */
	placeholder: string;
}

const Input = (props: Props) => {
	return <BasicInput {...props} autoFocus></BasicInput>;
};

Input.Password = BasicInput.Password;
Input.Search = BasicInput.Search;

export default Input;
