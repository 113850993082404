// local
export const OSCAR_USER_NAME = 'OSCAR_USER_NAME';
export const OSCAR_KEEP_LOGIN = 'OSCAR_KEEP_LOGIN';
export const OSCAR_PROTOCOL = 'OSCAR_PROTOCOL';
export const OSCAR_VERIFY_ACCOUNT = 'OSCAR_VERIFY_ACCOUNT';
// oscar verify by link(email or phone)
export const OSCAR_VERIFY_TYPE = 'OSCAR_VERIFY_TYPE';
export const OSCAR_DISPLAY_EMAIL = 'OSCAR_DISPLAY_EMAIL';
export const OSCAR_DEVICEID = 'OSCAR_DEVICEID';
export const OSCAR_DISPLAY_PHONE = 'OSCAR_DISPLAY_PHONE';
// Oscar verify 未验证方式类型
export const OSCAR_UNVERIFIED_TYPE = 'OSCAR_UNVERIFIED_TYPE';

// 安全验证方式
export const OSCAR_AUTHENTICATOR_VERIFY_TYPE = 'OSCAR_AUTHENTICATOR_VERIFY_TYPE';

// Oscar verify 可选择的验证方式类型
export const OSCAR_SELECT_VERIFY_TYPE = 'OSCAR_SELECT_VERIFY_TYPE';
// 是否开启自动推送
export const OSCAR_AUTO_PUSH = 'OSCAR_AUTO_PUSH';

// session
export const OSCAR_FROM = 'OSCAR_FROM';
export const OSCAR_QRCODE = 'OSCAR_QRCODE';
export const OSCAR_TENANT_KEY = 'OSCAR_TENANT_KEY';
export const OSCAR_REMAIN_SECOND = 'OSCAR_REMAIN_SECOND';
export const OSCAR_APPLY_FOR_UNLOCK = 'OSCAR_APPLY_FOR_UNLOCK';
export const OSCAR_AGENT_NAME = 'OSCAR_AGENT_NAME';
export const OSCAR_FROM_NAME = 'OSCAR_FROM_NAME';
export const OSCAR_STATE_TOKEN = 'OSCAR_STATE_TOKEN';
export const OSCAR_ACTIVATION_EMAIL_LIMIT_TIME = 'OSCAR_ACTIVATION_EMAIL_LIMIT_TIME';
export const OSCAR_SET_LATER = 'OSCAR_SET_LATER';
export const OSCAR_OPENID_URL = 'OSCAR_OPENID_URL';
export const OSCAR_LOGIN_APP_LOGO = 'OSCAR_LOGIN_APP_LOGO';
export const OSCAR_EMAIL_VERIFY_CODE_DISPLAY = 'OSCAR_EMAIL_VERIFY_CODE_DISPLAY';
export const OSCAR_ACTIVE_ENTRY = 'OSCAR_ACTIVE_ENTRY';
// 选择登录优先方式
export const OSCAR_LOGIN_ENTRANCE = 'OSCAR_LOGIN_ENTRANCE';
// 登录方式：直接登录or选择登录
export const OSCAR_LOGIN_METHOD_TYPE = 'OSCAR_LOGIN_METHOD_TYPE';

export const email_reg = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;

export const REM_SIZE = 20;
