import device from '@/utils/device';
import { Dispatch } from '@/models/store';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	apiAuthenticatorSelectConfirmListPost,
	apiAuthenticatorSelectSetListPost,
	apiBackToDashboardPost,
	apiBackToLoginPost,
	apiBackToPasswordPost,
	apiBackToSsaeAccountCheckPost,
} from '@/api';
import {
	OSCAR_AUTHENTICATOR_VERIFY_TYPE,
	OSCAR_AUTO_PUSH,
	OSCAR_DEVICEID,
	OSCAR_DISPLAY_EMAIL,
	OSCAR_DISPLAY_PHONE,
	OSCAR_FROM,
	OSCAR_KEEP_LOGIN,
	OSCAR_PROTOCOL,
	OSCAR_SELECT_VERIFY_TYPE,
	OSCAR_UNVERIFIED_TYPE,
	OSCAR_USER_NAME,
	OSCAR_VERIFY_ACCOUNT,
	OSCAR_VERIFY_TYPE,
} from '@/const';

import I18N from '@/utils/I18N';
import openHelpCenter from '@/utils/openHelpCenter';

import H5 from './h5';
import PC from './pc';

export type GO_BACK_TYPE =
	| 'login'
	| 'verify'
	| 'otherVerify'
	| 'passwordVerify'
	| 'helpCenter'
	| 'accountActivate';

interface Props {
	type: GO_BACK_TYPE | GO_BACK_TYPE[];
	textButton?: boolean;
}
interface OPTION {
	text: string;
	handler: () => void;
	visible: boolean;
}
export interface ComProps {
	options: OPTION[];
	textButton?: boolean;
}

export default function Wrapper(props: Props) {
	const dispatch = useDispatch<Dispatch>();
	const { type, ...rest } = props;
	let Com = device.isMobile ? H5 : PC;

	const isVerify = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('verify');
		}
		return type === 'verify';
	}, [type]);

	const isOtherVerify = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('otherVerify');
		}
		return type === 'otherVerify';
	}, [type]);

	const isLogin = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('login');
		}
		return type === 'login';
	}, [type]);

	const isPasswordVerify = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('passwordVerify');
		}
		return type === 'passwordVerify';
	}, [type]);

	const isHelpCenter = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('helpCenter');
		}
		return type === 'helpCenter';
	}, [type]);

	const isAccountActivate = useMemo(() => {
		if (Array.isArray(type)) {
			return type.includes('accountActivate');
		}
		return type === 'accountActivate';
	}, [type]);

	const removeLocalStorage = () => {
		// 除了 usename keepLogin deviceId 其他都删除, 不能直接调 clear, 会影响 dashboard
		let removeKeys = [
			OSCAR_PROTOCOL,
			OSCAR_VERIFY_ACCOUNT,
			OSCAR_VERIFY_TYPE,
			OSCAR_DISPLAY_EMAIL,
			OSCAR_DISPLAY_PHONE,
			OSCAR_UNVERIFIED_TYPE,
			OSCAR_AUTHENTICATOR_VERIFY_TYPE,
			OSCAR_SELECT_VERIFY_TYPE,
			OSCAR_AUTO_PUSH,
		];
		removeKeys.forEach((key) => {
			localStorage.removeItem(key);
		});
	};

	const options = useMemo(() => {
		const from = sessionStorage.getItem(OSCAR_FROM);
		const backToDashboard = {
			text: I18N.auto.returnToWorkbench,
			handler: async () => {
				try {
					const res = await apiBackToDashboardPost();
					location.href = res.success?.redirectUrl || '/login';
				} catch (err: any) {
					console.log(err, 'err');
				}
			},
			visible: true,
		};
		const backToLogin = {
			text: I18N.auto.backToLogin,
			handler: async () => {
				try {
					const res = await apiBackToLoginPost();
					removeLocalStorage();
					location.href = res.success?.redirectUrl || '/login';
				} catch (err: any) {
					console.log(err, 'err');
				}
			},
			visible: isLogin,
		};
		const verifySetting = {
			text: I18N.auto.returnToSafetyInspection,
			link: '/',
			handler: async () => {
				const res = await apiAuthenticatorSelectSetListPost();
				dispatch.next.nextPage({ res });
			},
			visible: isVerify,
		};
		const backToHelpCenter = {
			text: I18N.auto.backInHelp,
			handler: () => {
				openHelpCenter();
			},
			visible: isHelpCenter,
		};

		const backToAccountActivate = {
			text: I18N.auto.returnToAccountVerification,
			handler: async () => {
				try {
					const res = await apiBackToSsaeAccountCheckPost();
					removeLocalStorage();
					location.href = res.success?.redirectUrl || '/login';
				} catch (err: any) {
					console.log(err, 'err');
				}
			},
			visible: isAccountActivate,
		};
		const arr = [
			{
				text: I18N.auto.otherVerifiers,
				handler: async () => {
					const res = await apiAuthenticatorSelectConfirmListPost();
					dispatch.next.nextPage({ res });
				},
				visible: isOtherVerify,
			},
			{
				text: I18N.auto.backToPasswordVerification,
				handler: async () => {
					const res = await apiBackToPasswordPost();
					dispatch.next.nextPage({ res });
				},
				visible: isPasswordVerify,
			},
		];

		if (from === '5') {
			return [...arr, backToDashboard];
		}
		return [...arr, verifySetting, backToLogin, backToAccountActivate, backToHelpCenter];
	}, [isVerify, isLogin, isOtherVerify, isPasswordVerify]);

	return <Com {...rest} options={options}></Com>;
}
