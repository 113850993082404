import {
	apiInitPost,
	ApiInitPostRequest,
	ApiInitPostResponse,
	apiPagePhoneCodesPost,
	ApiPagePhoneCodesPostResponse,
} from '@/api';
import { OSCAR_DEVICEID, OSCAR_STATE_TOKEN } from '@/const';
import { getIsMobile } from '@/utils';
import { Option } from '@bedrock/components/lib/Select/interface';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

export interface InitRequest extends ApiInitPostRequest {
	token?: string;
}

export interface IndexBarOption {
	pinned: boolean;
	sortPrefix?: string;
	children: { phoneCode: string; name: string; countryCode: string }[];
}

export const app = createModel<RootModel>()({
	state: {
		tenantId: '',
		stateToken: '',
		areaCodeList: [] as Option[],
		mobileAreaCodeList: [] as IndexBarOption[],
		isMobile: getIsMobile(),
		fromInfo: {} as ApiInitPostResponse['fromInfo'],
	}, // initial state
	reducers: {
		setAreaCodeList(state, result: ApiPagePhoneCodesPostResponse) {
			const list: Option[] = [];
			const obj: Record<string, number> = {};
			(result.list || [])?.forEach((item) => {
				const value = `${item.name} ${item.phoneCode}`;
				if (!obj[value]) {
					list.push({
						value: `${item.name} ${item.phoneCode}`,
						label: `${item.phoneCode} (${item.name})`,
						name: item.phoneCode || '',
					});
					obj[value] = 1;
				}
			});
			return { ...state, areaCodeList: list };
		},
		setMobileAreaCodeList(state, result: ApiPagePhoneCodesPostResponse) {
			const map: Record<string, { pinned: boolean; sortPrefix?: string; children: any[] }> = {
				top: { pinned: true, children: [] },
			};
			(result.list || []).forEach((item) => {
				const data = {
					countryCode: item.nationCode,
					name: item.name,
					phoneCode: item.phoneCode,
				};
				if (!Number.isNaN(Number(item.pinyinSortPrefix))) {
					map['top'].children.push(data);
				} else if (map[item.pinyinSortPrefix as string]) {
					map[item.pinyinSortPrefix as string].children.push(data);
				} else {
					map[item.pinyinSortPrefix as string] = {
						pinned: false,
						sortPrefix: item.pinyinSortPrefix,
						children: [data],
					};
				}
			});
			return { ...state, mobileAreaCodeList: Object.values(map) };
		},
		setFormInfo(state, result: ApiInitPostResponse['fromInfo']) {
			return { ...state, fromInfo: result };
		},
	},
	effects: (dispatch) => ({
		async getInitData(payload: InitRequest) {
			const { token, ...rest } = payload;
			return apiInitPost({ ...rest, linkToken: token }, { showGlobalError: false }).then(
				(res: ApiInitPostResponse) => {
					console.warn('init res data:\n', res);
					res.deviceId && localStorage.setItem(OSCAR_DEVICEID, res.deviceId);
					sessionStorage.setItem(OSCAR_STATE_TOKEN, res.stateToken?.value || '');
					dispatch.app.setFormInfo(res.fromInfo);
					dispatch.next.nextPage({ res });
					return res;
				}
			);
		},
		async getAreaCodeList(payload, rootState) {
			if (rootState.app.areaCodeList.length === 0) {
				return apiPagePhoneCodesPost({ pageNum: 1, pageSize: 1000 }).then((res) => {
					dispatch.app.setMobileAreaCodeList(res);
					dispatch.app.setAreaCodeList(res);
					return res;
				});
			}
		},
	}),
});
