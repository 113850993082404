import { ChevronLeft } from '@bedrock/icons-react';
import classNames from 'classnames';
import styles from './pc.less';
import { ComProps } from '.';

export const GoBack = (props: ComProps) => {
	const { options } = props;

	return (
		<div className={classNames('flex flex-col', styles['go-back-wrap'])}>
			{options.map((item) => {
				return item.visible ? (
					<div
						className={classNames('flex items-center', styles['go-back-item'])}
						key={item.text}
						onClick={item.handler}
					>
						<ChevronLeft />
						<span className={styles.text}>{item.text}</span>
					</div>
				) : null;
			})}
		</div>
	);
};

export default GoBack;
