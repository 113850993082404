export default {
	announcement1:
		"Ngpush will be undergoing a maintenance (11:00-11:30 GMT+8, Monday, Jan 15th, 2024). Oscar Verify's push notification and verification services will be unavailable during this time.",
	continueWithOp: 'Continue with OpenID',
	continueWithOs: 'Continue with Oscar',
	sendMeEmail: 'Send Email',
	clickSendMeEmail: 'Select "{val1}" to get the verification message',
	getVerifyEmail: 'Verify Your Identity via Email',
	phoneSettingTips: 'Before you continue...',
	readAndAgree2: 'I have read and agree to the {val1} and agree to receive text message',
	seviceAgreement: 'Terms of Service',
	hasActivatedYour:
		'Your account{val1}has been activated. To use Dashboard, please set up your security verification methods.',
	inYourFrequentlyUsedMobile:
		'After installing Oscar Verify and setting up your account on your mobile devices, you can complete security verification using the one-time code or push notification from the app.',
	enableSecurityVerification: 'Add Verification Methods',
	afterActivationYouCan:
		'You can use the set up method for security verification when logging in to your Oscar account.',
	enableOsc: 'Get Started With Oscar Verify',
	step1Download: 'Step 1, download the app',
	youNeedToUse: 'Scan the QR code to download Oscar Verify on your device.',
	oscarVerify: 'Oscar Verify Installation Package',
	downloadedApp: 'Got the app and start set-up',
	step2Binding: 'Step 2, set up your account',
	selectInTheApplication:
		'In Oscar Verify, select "Add Account" and scan the QR code to add your account to the device. The page will refresh automatically upon completion.',
	bindQrCode: 'Scan to set up',
	switchToOtherBindings: 'Other set-up methods',
	notDownloadedYet: "Don't have Oscar Verify?",
	scanCodeToDownloadO_2: 'Download Oscar Verify',
	viewDownload2: 'Get a download QR code',
	throughMessageHorizontal: 'Verify with the banner or in-app notification',
	withinTheApplication: 'Verify with the in-app dynamic code',
	oscar_2: 'Oscar Verify provides two options for verification:',
	enableMobileVerification: 'Verify on Phone',
	logInToTheApp: 'SSO LOGIN',
	unableToRetrieve: 'Recover failed',
	enableSecurityVerification_2: 'ENABLE',
	startCertification: 'VERIFY',
	validateEmailTips:
		'A verification code has been sent to {val1}. Please check the email and enter the code below.',
	validateEmailTips2: 'Click the button below to request a verification email to {val1}.',
	verifyEmailSendTips:
		'A verification email has been sent to {val1}. Please check the email and open the verification link in the email or {val2}.',
	emailVerification: 'Verify with email',
	sendingAnEmail: 'Send verification email',
	pleaseResend: 'Please open the verification link in the email again.',
	setUpEmailVerification: 'Add email for verification',
	pleaseVerifyInTheEmail: 'Please enter the below six-digit code on the verification page.',
	ifYouDontHave: '-',
	verifyThatTheEmailHasBeenSent: 'Verification email sent',
	aiden: 'manually enter verification code',
	byBound_2: 'Receive verification message at selected email address',
	byBindingO: 'Receive push notification from Oscar Verify on mobile devices',
	throughOsc_2: 'Get verification code in Oscar Verify',
	enable: 'Enable',
	toEnsureTheAccount: 'It’s recommended to use your personal email for verification.',
	pleaseEnterYour_2: 'Please enter your email address',
	emailVerification_2: 'Verification email',
	bindEmailAccount: 'Set up an email address to receive verification code',
	smsVerification: 'SMS code',
	bindCommonlyUsedHands: 'Set up a mobile phone to receive SMS code',
	onMobileDevices:
		'Install and set up Oscar Verify on the mobile device to receive verification code or push notification',
	sent: 'Request limit exceeded',
	verificationEmailSent:
		"You've exceeded the verification email request limit. Please return to check the received email or try another verification method.",
	verifySmsSending:
		"You've exceeded the SMS code request limit. Please return to check the received messages or try another verification method.",
	linkExpired: 'Link expired',
	thisEmail:
		'This verification link has expired. To request another one, please go back to Sign-in and try again.',
	cancel: 'Cancel',
	nextStep2: 'Next',
	Recover: 'Recover',
	notFound: 'No results found',
	langSetting: 'Language Settings',
	languageSwitching: 'Switching language...',
	searchPlaceholder: 'Search',
	noResultFound: 'No results found',
	termsOfService: 'Terms of Service',
	loginTips: 'Before you continue',
	readAndAgree: 'Please read and agree to the {val1}',
	iAgree: 'I agree to the {val1}',
	Agree: 'Agree',
	NoPermission: 'No permission',
	UnableToSign: 'Unable to sign into your account',
	xiaZaiYingYong: 'Download',
	bangDingSheBei: 'Add',
	shangYiBu: 'Back',
	unableActivate: 'Failed to activate your account {val1}',
	exceedActivateTimes:
		"Too many activation requests have been initiated for your account {val1} today. Please don't make another activation request until the countdown ends.",
	activateEmailSendTips:
		'An activation email has been sent to {val1}. Please check the email and open the activation link in it.',
	validateActivateEmailTips:
		'A verification email has been sent to {val1}. To continue the verification, please enter the verification code in the email below.',
	exceptionReminder: 'Error',
	notAtPresent: 'Self-activation is unavailable',
	unableToActivate: 'Activation failed',
	activationExceeded: 'Too many activation attempts',
	theActivationMessageHasBeen: 'Activation email sent',
	backInHelp: 'Back to Help Center',
	returnToAccountVerification: 'Back to Verify Account',
	verificationCodeHasBeenSent: 'Verification code sent',
	accountActivation: 'Activate',
	activateYourO: 'Activate your Oscar account',
	pleaseEnterTheRequired: 'Enter the account name to activate',
	getVerificationCode: 'Get verification code',
	helpCenter: 'Help Center',
	queRenDengLu: 'Confirm Sign-in',
	queRenYiOS: 'Continue with Oscar',
	nonOverseasEmployees: 'Available to domestic users',
	signInWithOpenid: 'Sign in with Corp Email',
	accountNotActivated: 'Account not activated? {val1}',
	activated: 'Activate',
	currentOsc:
		'At the moment, only OC email account (@oc.netease.com) users can receive the activation email to activate their Oscar accounts.',
	pageLoadFailedAndRetry: 'Page load failed \nPlease try again',
	reload: 'Reload',
	pleaseSelect: 'please select',
	afterClickingTheButton:
		'Start downloading the app in the background and track progress on your Home screen',
	clickOnTheUpperRightCorner: 'Tap the',
	selectToBrowse: 'Select Open in Browser',
	youHaveLoggedOut: "You've been logged out",
	yourAccountHas: 'Your account has been logged out of this app',
	minute: 'minute',
	announcement:
		"Oscar will undergo a {val2} upgrade on {val1} for improved security and stability. You can still sign in to your Oscar account during the upgrade.\nBut Activation and Password Reset won't be available. ",
	thanks: 'For any support, contact oscarfeedback@oc.netease.com.',
	optional: '(Optional)',
	downloadNow: 'Download',
	backToPasswordVerification: 'Back to verify with password',
	otherVerifiers: 'Other verification methods',
	returnToSafetyInspection: 'Back to verification settings',
	backToLogin: 'Back to Sign-in',
	returnToWorkbench: 'Back to dashboard',
	resend: 'Resend',
	nextStep: 'Next',
	rememberAccountNumber: 'Remember account',
	pleaseEnterYour: 'OC or studio-specific email address',
	signIn: 'Sign in',
	theAccountFormatIs: 'Please enter account in the correct format',
	emailFormatIsError: 'Please enter a valid email address',
	logInToOsc: 'Sign in to Oscar Verify',
	logInToOsc2: 'Sign in to Oscar dashboard',
	logInToOsc3: 'Sign in as Oscar admin',
	selectSafetyInspection: 'Select verification methods',
	toVerify: 'Verify',
	throughOsc: 'Get verification code in Oscar Verify',
	verificationCode: 'Oscar Verify verification code',
	pushNotification: 'Oscar Verify Push Notification',
	byBound: 'Receive SMS code on the phone selected for verification',
	smsCode: 'Verify with SMS code',
	autoSendPush: 'Send push notification automatically',
	resendPush: 'Resend push notification',
	pushSent: 'Push notification sent',
	noPushReceived:
		'Please go to Oscar Verify to check the push notification on your device. The notification can be sent again if it is not received.',
	pushNotificationInspection: 'Verify with push notification',
	pushExpired: 'Push notification expired',
	youJustDeclined: 'You declined this sign-in',
	verificationNotReceived: 'Didn’t receive the code?',
	pleaseEnterBelow: 'Please enter the verification code sent to {val1} to complete verification.',
	smsCodeVerification: 'Verify with SMS code',
	verifying: 'Verifying',
	fromOsc:
		'Please enter the verification code you got from Oscar Verify app below to complete verification.',
	verificationCodeVerification: 'Verify with verification code',
	toSet: 'Set up',
	bindMobilePhoneTo: 'Send a verification code to your phone',
	mobilePhoneVerification: 'Verify on phone',
	oscar:
		'Oscar Verify is an authentication app that can be installed on your phone for sign-in verification.',
	afterSettingYouCan:
		'You can use the following methods to verify your identity and sign in to your Oscar account',
	setSafetyInspection: 'Manage verification methods',
	sendLink: 'Send link',
	pleaseEnterYour2: 'Please enter your email address',
	throughMailChain:
		'Complete the setting up through the link sent to your email link to enable you access your email on mobile devices.',
	setOsc: 'Set up Oscar Verify',
	unableToScan: "Can't scan?",
	scanTheLowerTwo: 'Scan the QR code below to add your account',
	openTheAppAnd: 'Open the app and select "Scan now" following the page guidance',
	scanInstallationPackage: 'Get the installer',
	scanCodeToDownloadO: 'Scan the QR code to download Oscar Verify app',
	downloadOsc: 'Download Oscar Verify app',
	scanSucceeded: 'Code scanned',
	clickRefresh: 'Click to refresh',
	qrCodeLost: 'QR code expired',
	setLater: 'Set up later',
	afterSettingYouCan2:
		'You can use the set up verification methods to verify your identity and sign in to your Oscar account.',
	otherSafetyInspection: 'Other verification methods',
	sendVerificationCode: 'Send verification code',
	pleaseEnterYour3: 'Enter phone number here',
	setMobilePhoneVerification: 'Add phone number for verification',
	china: '+86 (Chinese Mainland)',
	noLinkReceived: 'Didn’t receive the link?',
	pleaseUseMobileDevice:
		'Please open the link sent to {val1} on your mobile device and follow the on-screen instructions to complete verification.',
	openApp: 'Open app',
	shouldBeOpened:
		'Open app to complete verification. If you haven’t installed Oscar Verify on your device, please click the button below to download the installer.',
	oscar2:
		'Oscar Verify is a lightweight two-factor security authentication app for signing in to organization accounts. You can use it to verify your identity and sign in to your Oscar account.',
	applyForUnlocking: 'Unlock',
	hopesItCanBeSolvedImmediately: 'Unlock your account now?',
	requestSent: 'Unlocking request was sent',
	systemOccurred:
		'Unknown error. To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.',
	unknownError: 'Unknown error',
	searchIsNotSupportedTemporarily:
		"Your account{val1}doesn't support password recovery. To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.",
	yourAccount: 'Your account',
	unableToRetrieveTheSecret: 'Failed to recover password',
	refreshPage: 'please refresh and try again.',
	currentSystemRing: 'An error occurred in system environment, please refresh and try again.',
	systemAbnormality: 'Error',
	youHaveJustTaken: 'You’ve canceled this password recovery request, please go back to sign-in.',
	cancelRetrievingTheSecret: 'Cancel password recovery request',
	youAreTryingToUse: 'The link is invalid. Please go back to sign-in and request another URL.',
	linkHasExpired: 'Invalid link',
	ifYouHave:
		'This might be because you have reset your password or you attempted to use an invalid URL. To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.',
	ifYouHaveStimulated:
		'This might be because you have set up Oscar Verify or you attempted to use an invalid link. Please go back to sign-in and request another link.To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.',
	ifYouHaveStimulated2:
		'This might be because you have activated your account or you attempted to use an invalid URL. To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.',
	unlockingTime: 'Unlocked at:',
	abnormalRegistration:
		'Your account{val1}has been locked due to an unusual sign-inactivity. To continue, you should unlock your account. {val2}',
	accountLocked: 'Account locked',
	invalid:
		'The current action is unavailable due to the deactivation of your account. Please contact your administrator for further assistance.',
	accountInvalid: 'Account deactivated',
	dueToBeingInNon: 'You have been logged out due to inactivity. Please go back to sign-in.',
	sessionTimeOut: 'Session timed out',
	noMailReceived: 'Didn’t receive the email?',
	retrievePassword: 'Recover password',
	itsMe: "Yes, it's me",
	noNotMe: 'No, it’s not me',
	didYouJust: 'Are you trying to sign in?',
	youAreAlreadyInAnother: 'Password recovery has been continued on another page.',
	pageIsBeingCalibrated: 'Verifying, please wait...',
	determine: 'OK',
	pleaseEnterAgain: 'Confirm new password',
	andTheFirstLoss: 'Enter the same password again',
	pleaseEnterANewSecret: 'Please enter new password',
	setNewPassword: 'Reset password',
	notTheSameAsTheAccountName: 'Different from the password of your organization account',
	includingCapitalLetters:
		'Include at least one upper/lowercase letter, one number, one special character',
	atLeastCharacters: 'At least 8 characters, and no more than 20.',
	operationSucceeded: 'Done',
	sPasswordHasBeenReset:
		'The password for {val1} has been reset. To use Dashboard, please verify your identity again.',
	resetSucceeded: 'Password reset',
	refreshUnlocked:
		'Your account{val1}has been unlocked. Please refresh or click Back to Sign-in to retry.',
	accountUnlocked: 'Account unlocked',
	activationSucceeded: 'Account activated',
	pleaseEnterTemporary: 'Please enter temporary password',
	passwordVerification: 'Verify with your password',
	forgotPassword: 'Forgot password?',
	verification: 'Verify',
	inCaseOfAnyDifficulty:
		'To get further assistance with your account, please search IT Helpdesk on POPO / Teams / Slack.',
	pleaseEnterTheAccountNumber: 'Please enter account password',
	ifYouForget: 'Forgot your password? You can recover it.',
	continuous:
		'{val1}failed attempts in a row. The account will be locked after{val2}more failed attempts, and unlocked after{val3}hours. ',
	tooManyErrors: 'Too many failed attempts',
	hoursIfYou2: 'Forgot the temporary password? Please check the activation email.',
	continueTyping: 'Cancel',
	whetherToRetrieveTheSecret: 'Recover password?',
	networkAnomaly: 'A network error occurred',
	selectCountryAnd: 'Choose country/region',
	complete: 'Done',
	appliedForUnlocking: 'Request sent',
	iGotIt: 'Got it',
	didYouForgetToFace: 'Forgot your temporary password?',
	sendEmail:
		'Email has been sent to {val1}. To complete the verification, please open the URL in the email or enter the verification code in the email below.',
};
