import device from '@/utils/device';
import H5 from './h5';
import PC from './pc';

export interface Props {
	children: React.ReactNode;
	icon?: React.ReactNode;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	title: string;
	username?: boolean;
	className?: string;
	bottomNode?: React.ReactNode;
	contentClassName?: string;
}
export default function Wrapper(props: Props) {
	let Com = device.isMobile ? H5 : PC;

	return <Com {...props}></Com>;
}
