import classNames from 'classnames';
import { FC } from 'react';

import PageBox from '@/components/pageBox';
import { OSCAR_USER_NAME } from '@/const';
import useShowInput from '@/hooks/useInputShow';

import { Props } from './index';
import styles from './pc.less';

export const Wrapper: FC<Props> = ({
	children,
	title,
	icon,
	username = true,
	header,
	footer,
	className,
	bottomNode,
	contentClassName,
}) => {
	const account = localStorage.getItem(OSCAR_USER_NAME);
	useShowInput();

	return (
		<PageBox bottomNode={bottomNode}>
			<div
				className={classNames(
					'flex flex-col items-start justify-center',
					styles['wrapper'],
					className
				)}
			>
				{header ? (
					<div className={classNames('flex items-center', styles.header)}>{header}</div>
				) : (
					<div className={styles.header}>
						{icon && <div className={classNames('flex ', styles.logo)}>{icon}</div>}
						<div className={classNames(styles.title, { [styles.noname]: !username })}>{title}</div>
						{username && (
							<div className={classNames('flex justify-start', styles.username)}>
								<span className={styles.account}>{account}</span>
							</div>
						)}
					</div>
				)}
				<div className={classNames(styles['wrapper-content'], contentClassName)}>{children}</div>
				{footer && <div className={styles['wrapper-footer']}>{footer}</div>}
			</div>
		</PageBox>
	);
};

export default Wrapper;
