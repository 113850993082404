// 埋点的event key列表
export const CountlyChangeLang = 'dashboard_change_lang';
export const CountlyUserBrowser = 'user_browser';

// 自定义错误name列表
export const ScoutBoundaryError = 'BoundaryError';
export const ScoutApiNotAvailabelError = 'ApiNotAvailabelError';
export const ScoutLoginSessionOutTimeError = 'LoginSessionOutTimeError';
export const ScoutSpectialRequestError = 'SpectialRequestError';
export const ScoutPushVerifyOutTimeError = 'PushVerifyOutTimeError';
