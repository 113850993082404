import { createFromIconfontCN } from '@bedrock/icons-react';
import { FC } from 'react';
const IconFont = createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/c/font_3698951_xky9vod7cu.js',
});

interface Props {
	name: string;
}
const Icon: FC<Props> = ({ name }) => {
	return <IconFont type={name} />;
};

export default Icon;
