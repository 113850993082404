// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box___Dostk {\n  width: 19rem;\n  min-height: 10rem;\n  box-sizing: border-box;\n  max-width: 100%;\n  background: transparent;\n  box-shadow: none;\n  border-radius: 0;\n  border-radius: initial;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pageBox/h5.less"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;EAAA,sBAAA;AACD","sourcesContent":[".box {\n\twidth: 380px;\n\tmin-height: 200px;\n\tbox-sizing: border-box;\n\tmax-width: 100%;\n\tbackground: transparent;\n\tbox-shadow: none;\n\tborder-radius: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "box___Dostk"
};
export default ___CSS_LOADER_EXPORT___;
