import { OSCAR_USER_NAME } from '@/const';
import { isProd } from './env';
import { isIE } from './device';

const ScoutAppId = '44a4d3db';
const ScoutAppTestId = '546f78b6';

const ScuoutExcludeUrls = [/^(https?:\/\/)?(www\.)?localhost[/:]?.*/];

export interface ScoutError {
	name: string;
	message: string;
	stack?: string;
}

const enhanceOnError = () => {
	window.onerror = function errorBundler(msg, url, line, col, err) {
		// old browsers like IE 10 and Safari 9 won't give this value 'err' to us, but if it is provided we can trigger error recording immediately
		// console.log('onError', msg, url, line, col, err);
		if (err !== undefined && err !== null) {
			scoutTrackError(err);
		} else {
			// window.event ie中使用
			col = col || (window.event && (window.event as any).errorCharacter);
			let error: Error = new Error();
			if (msg) {
				error.message += msg + '\n';
			}
			if (url) {
				error.message += 'at ' + url;
			}
			if (line) {
				error.message += ':' + line;
			}
			if (col) {
				error.message += ':' + col;
			}
			error.message += '\n';
			try {
				let stack = [];
				// deprecated, must be changed
				// eslint-disable-next-line no-caller
				let f = errorBundler.caller;
				while (f) {
					stack.push(f.name);
					f = f.caller;
				}
				error.stack += stack.join('\n');
			} catch (ex) {
				console.error('window onError, Call stack generation experienced a problem:', ex);
			}
			// false indicates fatal error (as in non_fatal:false)
			scoutTrackError(error);
		}
	};
};

export const scoutLogin = (user?: string) => {
	const Scout = window.Scout;
	if (!Scout) {
		return;
	}

	if (!Scout.__isInit) {
		// 还没有初始化，缓存id
		Scout.__cacheDeviceId = user;
		console.warn('update id before Scout init');
		return;
	}

	// 重置缓存
	if (user) {
		Scout.__cacheDeviceId = undefined;
	}

	const userName = user || Scout.__cacheDeviceId;

	if (!userName) {
		return;
	}

	try {
		Scout.setUserData({ userName });
		console.warn('scout set user success', userName);
	} catch (error) {
		console.error('scout setUserName failed', error);
	}
};

export const initScout = (callback?: () => void) => {
	try {
		console.warn('load Scout ...');

		const cly = document.createElement('script');
		cly.type = 'text/javascript';
		cly.async = true;
		cly.src = 'https://unpkg.oa.netease.com/@scout/browser/dist/scout.iife.min.js';
		cly.onload = () => {
			try {
				console.warn('init Scout ...');
				if (window.Scout) {
					if (isIE) {
						console.warn('is ie browser');
					}
					window.Scout.init({
						appId: isProd() ? ScoutAppId : ScoutAppTestId,
						instrumentEnable: {
							fetch: true, // 启动fetch劫持
							xhr: true, // 启动xhr劫持
							resourceTiming: !isIE, // ie浏览器关闭静态资源分析，因为scout静态资源方法不兼容ie11浏览器
						},
						errorRepeat: 1,
						excludeUrls: ScuoutExcludeUrls,
					});
					enhanceOnError();
					console.warn('init Scout success');
					window.Scout.__isInit = true;
					scoutLogin(localStorage?.getItem(OSCAR_USER_NAME) || '');
					callback?.();
				}
			} catch (error) {
				console.error('init Scout failed:\n', error);
				callback?.();
			}
		};
		const s = document.getElementsByTagName('script')[0];
		s.parentNode?.insertBefore(cly, s);
	} catch (error) {
		console.error('load Scout failed', error);
	}
};

const matchUrlInArray = (url: string, patterns: Array<string | RegExp>) => {
	for (let pattern of patterns) {
		if (pattern instanceof RegExp) {
			if (pattern.test(url)) {
				return true;
			}
		} else if (typeof pattern === 'string') {
			if (url.includes(pattern)) {
				return true;
			}
		}
	}
	return false;
};

/**
 * 手动上报错误
 */
export const scoutTrackError = (error: ScoutError) => {
	const Scout = window.Scout;
	if (!Scout || !error.name) {
		return;
	}

	try {
		// 判断屏蔽的url
		const url = window.location.href;

		if (matchUrlInArray(url, ScuoutExcludeUrls)) {
			// 属于被屏蔽的url，不进行上传
			return;
		}
		// console.log('scout track error', error);
		Scout.setMetaData({ cookie: document.cookie });
		Scout.captureError(error);
	} catch (error) {
		console.error('add Scout error failed', error);
	}
};
