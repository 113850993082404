// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".go-back-wrap___uvmAI {\n  color: var(--PopoTextTertiary);\n  margin-right: auto;\n  font-weight: 500;\n  font-size: 0.75rem;\n  line-height: 0.9rem;\n}\n.go-back-wrap___uvmAI .rock-btn {\n  margin-bottom: 0.6rem;\n}\n.go-back-wrap___uvmAI .rock-btn:last-child {\n  margin-bottom: 0rem;\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU {\n  padding: 0.4rem 0;\n  margin-bottom: 0.6rem;\n  cursor: pointer;\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU:active {\n  color: var(--br50);\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU:last-child {\n  margin-bottom: 0;\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU .rock-icon-chevron-left {\n  font-size: 1rem;\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU:hover {\n  color: var(--br50);\n}\n.go-back-wrap___uvmAI .go-back-item___Vv9BU:active {\n  color: var(--br60);\n}\n", "",{"version":3,"sources":["webpack://./src/components/goBack/h5.less"],"names":[],"mappings":"AAAA;EACC,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACD;AANA;EAQG,qBAAA;AACH;AAAG;EACC,mBAAA;AAEJ;AAZA;EAeE,iBAAA;EACA,qBAAA;EAOA,eAAA;AANF;AAAE;EACC,kBAAA;AAEH;AAAE;EACC,gBAAA;AAEH;AAvBA;EA0BI,eAAA;AAAJ;AAGE;EACC,kBAAA;AADH;AAGE;EACC,kBAAA;AADH","sourcesContent":[".go-back-wrap {\n\tcolor: var(--PopoTextTertiary);\n\tmargin-right: auto;\n\tfont-weight: 500;\n\tfont-size: 15px;\n\tline-height: 18px;\n\t:global {\n\t\t.rock-btn {\n\t\t\tmargin-bottom: 12px;\n\t\t\t&:last-child {\n\t\t\t\tmargin-bottom: 0px;\n\t\t\t}\n\t\t}\n\t}\n\t.go-back-item {\n\t\tpadding: 8px 0;\n\t\tmargin-bottom: 12px;\n\t\t&:active {\n\t\t\tcolor: var(--br50);\n\t\t}\n\t\t&:last-child {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t\tcursor: pointer;\n\t\t:global {\n\t\t\t.rock-icon-chevron-left {\n\t\t\t\tfont-size: 20px;\n\t\t\t}\n\t\t}\n\t\t&:hover {\n\t\t\tcolor: var(--br50);\n\t\t}\n\t\t&:active {\n\t\t\tcolor: var(--br60);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"go-back-wrap": "go-back-wrap___uvmAI",
	"go-back-item": "go-back-item___Vv9BU"
};
export default ___CSS_LOADER_EXPORT___;
