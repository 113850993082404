// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionBar___bdaSi {\n  margin: 1rem 0;\n  width: 19rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/actionBar/index.less"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,YAAA;AACD","sourcesContent":[".actionBar {\n\tmargin: 20px 0;\n\twidth: 380px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBar": "actionBar___bdaSi"
};
export default ___CSS_LOADER_EXPORT___;
