import ppIntl from '@pp-i18n/intl';
import dayjs from 'dayjs';

import enUsLangs from '../../.ppi18n/en-US';
import frCALangs from '../../.ppi18n/fr-CA';
import jaJPLangs from '../../.ppi18n/ja-JP';
import zhCNLangs from '../../.ppi18n/zh-CN';

export { ppIntl };

export enum LangEnum {
	'zh-CN' = 'zh-CN',
	'zh' = 'zh-CN',
	'en-US' = 'en-US',
	'en' = 'en-US',
	'ja-JP' = 'ja-JP',
	'ja' = 'ja-JP',
	'fr' = 'fr-CA',
	'fr-CA' = 'fr-CA',
}

// export enum ServerEnum {
// 	'zh' = 'zh-CN',
// 	'en' = 'en-US',
// 	'ja' = 'ja-JP',
// }

const setCookie = (locale: LangEnum) => {
	document.cookie = `oscar-auth-locale=${locale}; path=/`;
};
/**
 * 获取当前语言的Cookie
 */
export function getCurrentLang(): LangEnum {
	const language = /OscarLanguage\/([a-zA-Z_-]+)/
		.exec(window.navigator.userAgent)?.[1]
		.replace('_', '-');

	const cookieLang = (document.cookie.match(/oscar-auth-locale=([^;$]+)/) || [])[1];

	let urlSearchParam = new URLSearchParams(location.search);
	let queryLang = urlSearchParam.get('locale');

	const acceptLanguage = navigator.language;
	let acceptLanguagePrefix = acceptLanguage;
	if (acceptLanguage.indexOf('-') > 0) {
		acceptLanguagePrefix = acceptLanguage.slice(0, acceptLanguage.indexOf('-'));
	}

	// 客户端语言 > urlQuery > cookie > 浏览器默认语言
	const lang = cookieLang ? cookieLang.split(' ')[0] : '';
	if (Object.keys(LangEnum).includes(language as string)) {
		return language as LangEnum;
	} else if (Object.keys(LangEnum).includes(queryLang as string)) {
		setCookie(queryLang as LangEnum);
		return queryLang as LangEnum;
	} else if (lang) {
		return lang as LangEnum;
	} else if (Object.keys(LangEnum).includes(acceptLanguagePrefix as string)) {
		return LangEnum[acceptLanguagePrefix as keyof typeof LangEnum];
	}
	// 如果是不支持的语种，默认显示英文
	return LangEnum['en-US'];
}

const langs = {
	'en-US': enUsLangs,
	'zh-CN': zhCNLangs,
	'ja-JP': jaJPLangs,
	'fr-CA': frCALangs,
	zh: zhCNLangs,
	en: enUsLangs,
	ja: jaJPLangs,
	fr: frCALangs,
};
// 从 Cookie 中取语言值, 默认为 zh-CN
const defaultLang = getCurrentLang();

let curLang;
if (Object.keys(langs).indexOf(defaultLang) > -1) {
	curLang = defaultLang;
} else {
	// 如果没有对应的语言文件, 置为中文
	curLang = 'zh-CN';
}

const I18N = ppIntl.init(curLang, langs);
dayjs.locale(curLang.toLocaleLowerCase() as string);

export function setLang(lang: LangEnum) {
	setCookie(lang);
	I18N.setLang && I18N.setLang(lang);
}

export default I18N as typeof I18N & { template(str: string, args: object): string };
