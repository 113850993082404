function setupWKWebViewJavascriptBridge(callback: (bridge: any) => void) {
	const bridge = window.WebViewJavascriptBridge || window.WKWebViewJavascriptBridge;
	if (bridge) {
		return callback(bridge);
	}
	const callbacks = window.WVJBCallbacks || window.WKWVJBCallbacks;
	if (callbacks) {
		return callbacks.push(callback);
	}
	window.WVJBCallbacks = window.WKWVJBCallbacks = [callback];

	//for https://github.com/marcuswestin/WebViewJavascriptBridge
	const WVJBIframe = document.createElement('iframe');
	WVJBIframe.style.display = 'none';
	WVJBIframe.src = 'https://__bridge_loaded__';
	document.documentElement.appendChild(WVJBIframe);
	setTimeout(function () {
		document.documentElement.removeChild(WVJBIframe);
	}, 0);

	//for https://github.com/Lision/WKWebViewJavascriptBridge
	window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
}
window.jsBridge = {
	callHandler: function (name: string, data: any, callback: () => void) {
		setupWKWebViewJavascriptBridge(function (bridge) {
			bridge.callHandler(name, data, callback);
		});
	},
	registerHandler: function (name: string, callback: () => void) {
		setupWKWebViewJavascriptBridge(function (bridge) {
			bridge.registerHandler(name, callback);
		});
	},
};

interface Response {
	status: number;
	data: any;
	message: string;
}

export const sendOscarTOTPQrCode = (qrcode: string, cb: (res: Response) => void) => {
	window.jsBridge.callHandler('sendOscarTOTPQrCode', { qrcode }, (res: Response) => {
		cb && cb(res);
	});
};

export const openInnerWebview = (url: string, title: string, cb: (res: Response) => void) => {
	window.jsBridge.callHandler('openInnerWebview', { url, title }, (res: Response) => {
		cb && cb(res);
	});
};
