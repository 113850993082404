import {
	apiOscarVerifyUnbindPost,
	apiSecurityEmailUnbindPost,
	apiSecurityPhoneUnbindPost,
} from '@/api';
import { sendOscarTOTPQrCode } from '@/utils/bridge';
import { scoutLogin } from '@/utils/scout';

import { Message } from '@/components';
import {
	OSCAR_ACTIVATION_EMAIL_LIMIT_TIME,
	OSCAR_ACTIVE_ENTRY,
	OSCAR_AGENT_NAME,
	OSCAR_APPLY_FOR_UNLOCK,
	OSCAR_AUTHENTICATOR_VERIFY_TYPE,
	OSCAR_AUTO_PUSH,
	OSCAR_DISPLAY_EMAIL,
	OSCAR_DISPLAY_PHONE,
	OSCAR_EMAIL_VERIFY_CODE_DISPLAY,
	OSCAR_FROM,
	OSCAR_FROM_NAME,
	OSCAR_LOGIN_APP_LOGO,
	OSCAR_OPENID_URL,
	OSCAR_QRCODE,
	OSCAR_SELECT_VERIFY_TYPE,
	OSCAR_SET_LATER,
	OSCAR_UNVERIFIED_TYPE,
	OSCAR_USER_NAME,
	OSCAR_LOGIN_ENTRANCE,
	OSCAR_LOGIN_METHOD_TYPE,
} from '@/const';
import { HandlerType, AppLoginMethodType } from '@/types';
import { createModel } from '@rematch/core';
import { history } from '@umijs/max';
import { RootModel } from '.';

export const nextTypeRouteMap: Record<HandlerType, string> = {
	[HandlerType.AccountCheck]: '/account',
	[HandlerType.PasswordSet]: '/password',
	[HandlerType.ActiveSuccess]: '/success/activation',
	[HandlerType.ResetSuccess]: '/success/reset',
	[HandlerType.RecoverPasswordWithLink]: '/findPwd/email',
	[HandlerType.RecoverPasswordWithCode]: '/findPwd/captcha',
	[HandlerType.OscarVerifyBindLinkTransfer]: '/bindVerify',
	[HandlerType.OscarVerifyBindQrcode]: '/authenticator/setting/oscarQrcode',
	[HandlerType.OscarVerifyBindLink]: '/authenticator/setting/oscarLink',
	[HandlerType.OscarVerifyBindLinkWait]: '/authenticator/setting/waiting',
	[HandlerType.PhoneSet]: '/authenticator/setting/phone',
	[HandlerType.PhoneSetOtpVerify]: '/authenticator/setting/phoneCaptcha',
	[HandlerType.EmailSet]: '/authenticator/setting/email',
	[HandlerType.EmailSetOtpVerify]: '/authenticator/setting/emailCaptcha',
	[HandlerType.RecoverPasswordCancel]: '/error/findPwdCancel',
	[HandlerType.RecoverPasswordModifiedByOther]: '/error/findPwdTips',
	[HandlerType.AuthenticatorSelectConfirmList]: '/authenticator/select',
	[HandlerType.AuthenticatorSelectSetList]: '/authenticator/setting',
	[HandlerType.AuthenticatorPassword]: '/verify',
	[HandlerType.AuthenticatorOscarVerifyPush]: '/authenticator/select/push',
	[HandlerType.AuthenticatorOscarVerifyOTP]: '/authenticator/select/token',
	[HandlerType.AuthenticatorPhone]: '/authenticator/select/sms',
	[HandlerType.PreAuthenticatorEmail]: '/authenticator/select/email',
	[HandlerType.PreAuthenticatorEmailSend]: '/authenticator/select/emailSend',
	[HandlerType.AuthenticatorEmailVerify]: '/authenticator/select/emailVerify',
	[HandlerType.OtherAuthenticatorSelectSetList]: '/authenticator/setting/other',
	[HandlerType.AuthenticatorEmailCodeDisplay]: '/authenticator/select/emailCodeDisplay',
	[HandlerType.AuthenticatorPasswordTemp]: '/temporaryPwd',
	[HandlerType.AccountLocked]: '/error/lock',
	[HandlerType.SessionTimeout]: '/error/timeout',
	[HandlerType.AccountUnLocked]: '/success/unlock',
	[HandlerType.ActiveLinkRot]: '/error/lapsedActivate',
	[HandlerType.ResetPasswordLinkRot]: '/error/lapsedReset',
	[HandlerType.RecoverPasswordLinkRot]: '/error/lapsedLink',
	[HandlerType.OscarVerifyBindActiveLinkRot]: '/error/lapsedOscarVerify',
	[HandlerType.UnknownError]: '/error/unknownError',
	[HandlerType.AccountFailure]: '/error/accountFailure',
	[HandlerType.CanNotRecoverPassword]: '/error/cannotFindPwd',
	[HandlerType.SSAEAccountCheck]: '/activate',
	[HandlerType.SSAEVerifyValidationEmail]: '/activate/captcha',
	[HandlerType.SSAESendActivationEmailTooMuch]: '/error/exceedActivateTimes',
	[HandlerType.SSAEAccountUnableToActive]: '/error/unableActivate',
	[HandlerType.SSAESendActivationEmailSuccess]: '/success/activateEmailSend',
	[HandlerType.SSAESelfActivationNotOpen]: '/error/activateError',
	[HandlerType.OtpVerifyLimit]: '/error/otpEmailLimit',
	[HandlerType.OtpPhoneVerifyLimit]: '/error/otpPhoneLimit',
	[HandlerType.SelectOtpVerifyLimit]: '/error/selectOtpEmailLimit',
	[HandlerType.SelectOtpPhoneVerifyLimit]: '/error/selectOtpPhoneLimit',
	[HandlerType.SecondConfirmWithCurrentAccount]: '/confirmLogin',
	[HandlerType.AuthenticatorEmailLinkExpired]: '/error/emailLinkExpired',
	// 额外处理，不直接跳转
	[HandlerType.DoNothing]: '/',
	[HandlerType.PhoneDelete]: '/',
	[HandlerType.EmailDelete]: '/',
	[HandlerType.OscarVerifyUnbind]: '/',
	[HandlerType.RedirectToBindOscarVerifyInAppAfterLogin]: '/',
	[HandlerType.RedirectTo]: '/',
};

export const next = createModel<RootModel>()({
	state: {
		nextType: -1,
		stateTokenTimer: 0,
	},
	reducers: {
		setNextType(state, nextType?: number) {
			return { ...state, nextType: nextType || -1 };
		},
		setTimer(state, timer: any) {
			return { ...state, stateTokenTimer: timer };
		},
	},
	effects: (dispatch) => ({
		checkStateTokenExpired(expired: number, rootState) {
			clearTimeout(rootState.next.stateTokenTimer);
			const now = new Date().getTime();
			let time = expired - now;
			// 5s 容错
			if (time > 5000) {
				time = time - 5000;
				const timer = setTimeout(() => {
					history.replace(nextTypeRouteMap[HandlerType.SessionTimeout]);
				}, time);
				dispatch.next.setTimer(timer);
			} else {
				history.replace(nextTypeRouteMap[HandlerType.SessionTimeout]);
			}
		},
		nextPage(payload) {
			const { res } = payload;
			const nextType = res?.next?.type as HandlerType;
			res?.stateToken?.expireAt && dispatch.next.checkStateTokenExpired(res.stateToken.expireAt);
			if (nextType === HandlerType.DoNothing) {
				return;
			}
			dispatch.next.setNextType(nextType);
			if (res.success?.redirectUrl) {
				location.href = res.success?.redirectUrl;
				return;
			}
			// 客户端扫码绑定
			const isOscar = window.navigator.userAgent.includes('Oscar');
			if (
				nextType === HandlerType.RedirectToBindOscarVerifyInAppAfterLogin &&
				isOscar &&
				res.next?.bindInfo?.qrcode
			) {
				sendOscarTOTPQrCode(res.next.bindInfo.qrcode, (res) => {
					if (res.status === 0) {
						Message.error(res.message);
					}
				});
				return;
			}
			let url = nextTypeRouteMap[nextType];
			const {
				authenticatorSelectSetList = [],
				authenticatorSelectConfirmList = [],
				oscarVerifyAutoSendPushOption = 0,
				displayEmails = [],
				displayPhone = '',
				bindInfo = {},
				applyForUnlock = false,
				parentAgentName,
				parentFromName,
				redirectUrl = '',
				activationEmailSendLimitTime,
				authenticatorSelectSetListSetLater,
				openIDEntryUrl = '',
				activeEntryShow = false,
				appLogoUrl = '',
				displayOtp = '',
				loginMethodType = AppLoginMethodType.Direct,
				selectTopEntrance,
			} = res.next || {};
			res.oscarUserName && localStorage.setItem(OSCAR_USER_NAME, res.oscarUserName);
			if (res.oscarUserName) {
				scoutLogin(res.oscarUserName);
			}
			localStorage.setItem(OSCAR_DISPLAY_EMAIL, (displayEmails || []).join(','));
			localStorage.setItem(OSCAR_DISPLAY_PHONE, displayPhone);
			localStorage.setItem(OSCAR_AUTO_PUSH, oscarVerifyAutoSendPushOption);
			sessionStorage.setItem(OSCAR_FROM, res.fromInfo?.from);
			sessionStorage.setItem(OSCAR_APPLY_FOR_UNLOCK, applyForUnlock);
			sessionStorage.setItem(OSCAR_SET_LATER, authenticatorSelectSetListSetLater);
			sessionStorage.setItem(OSCAR_EMAIL_VERIFY_CODE_DISPLAY, displayOtp);
			parentAgentName && sessionStorage.setItem(OSCAR_AGENT_NAME, parentAgentName);
			parentFromName && sessionStorage.setItem(OSCAR_FROM_NAME, parentFromName);
			activationEmailSendLimitTime &&
				sessionStorage.setItem(OSCAR_ACTIVATION_EMAIL_LIMIT_TIME, activationEmailSendLimitTime);
			sessionStorage.setItem(OSCAR_OPENID_URL, openIDEntryUrl);
			sessionStorage.setItem(OSCAR_LOGIN_METHOD_TYPE, loginMethodType);
			sessionStorage.setItem(OSCAR_LOGIN_ENTRANCE, selectTopEntrance ?? '');
			sessionStorage.setItem(OSCAR_ACTIVE_ENTRY, activeEntryShow);
			appLogoUrl && sessionStorage.setItem(OSCAR_LOGIN_APP_LOGO, appLogoUrl);

			bindInfo && sessionStorage.setItem(OSCAR_QRCODE, bindInfo?.qrcode);
			if (
				nextType === HandlerType.OtherAuthenticatorSelectSetList &&
				authenticatorSelectSetList &&
				authenticatorSelectSetList.length > 0
			) {
				localStorage.setItem(
					OSCAR_UNVERIFIED_TYPE,
					JSON.stringify(
						(authenticatorSelectSetList as Record<string, string>[]).map((item) => item.type)
					)
				);
			}
			if (
				nextType === HandlerType.AuthenticatorSelectSetList &&
				authenticatorSelectSetList &&
				authenticatorSelectSetList.length > 0
			) {
				localStorage.setItem(
					OSCAR_AUTHENTICATOR_VERIFY_TYPE,
					JSON.stringify(
						(authenticatorSelectSetList as Record<string, string>[]).map((item) => item.type)
					)
				);
			}

			let arr: Array<[string, [string]]> = [];
			(authenticatorSelectConfirmList || []).forEach((item: any) => {
				const { type = 0, methods = [] } = item;
				if (type) {
					arr.push([type, methods.map((v: any) => v.type as number)]);
				}
			});

			localStorage.setItem(OSCAR_SELECT_VERIFY_TYPE, JSON.stringify(arr));
			if (nextType === HandlerType.OscarVerifyUnbind) {
				apiOscarVerifyUnbindPost().then((res) => {
					dispatch.next.nextPage({ res });
				});
				return;
			} else if (nextType === HandlerType.PhoneDelete) {
				apiSecurityPhoneUnbindPost().then((res) => {
					dispatch.next.nextPage({ res });
				});
				return;
			} else if (nextType === HandlerType.EmailDelete) {
				apiSecurityEmailUnbindPost().then((res) => {
					dispatch.next.nextPage({ res });
				});
				return;
			} else if (nextType === HandlerType.RedirectTo) {
				location.href = redirectUrl;
				return;
			}
			history.replace(url);
		},
	}),
});
