import { Message } from '@/components';
import { OSCAR_DEVICEID, OSCAR_STATE_TOKEN } from '@/const';
import { nextTypeRouteMap } from '@/models/next';
import { HandlerType } from '@/types';
import I18N, { getCurrentLang } from '@/utils/I18N';
import { history } from '@umijs/max';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { RequestFunctionParams } from 'yapi-to-typescript';
import { RequestBodyType } from 'yapi-to-typescript';
import { scoutTrackError } from '@/utils/scout';
import { ScoutLoginSessionOutTimeError, ScoutSpectialRequestError } from '@/utils/trackKeys';

export interface RequestOptions {
	mock?: boolean;
	//接口错误是否弹窗
	showError?: boolean;
	// 是否显示全局错误
	showGlobalError?: boolean;
}
const axios = Axios.create({
	timeout: 30000, //5s超时时间
});

enum ERROR_STATUS {
	NOT_LOGIN = 305, // 用户未登录
	NOT_FOUND = 404, // 数据不存在
	NOT_ALLOWED = 405, // 没有权限操作
	PARAMS_ERROR = 414, // 参数错误
	SERVICE_TEMPORARILY_UNAVAILABLE = 503,
	UNKNOWN_ERROR = 500, // 未知错误
}

interface ResBaseBody<T> {
	status: number;
	message: string;
	data: T;
}

class ServerResError extends Error {
	code?: number;
	data?: any;
	constructor(public message: string, code?: number, data?: any) {
		super(message);
		this.code = code;
		this.data = data;
	}
}

export default function request<TResponseData>(
	payload: RequestFunctionParams,
	options: RequestOptions = {
		mock: false,
		showError: false,
		showGlobalError: true,
	}
): Promise<TResponseData> {
	// 基本地址
	const baseUrl = options.mock ? payload.mockUrl : location.origin;

	// 请求地址
	const url = `${baseUrl}${payload.path}`;

	// 具体请求逻辑
	const axiosOptions: AxiosRequestConfig = {
		url,
		method: payload.method,
		headers: {
			'tenant-key': sessionStorage.getItem('OSCAR_TENANT_KEY') || 'oc',
			'device-id': localStorage.getItem(OSCAR_DEVICEID) || '',
			'TARGET-SERVER': sessionStorage.getItem('TARGET-SERVER') || '',
			'session-state-token': sessionStorage.getItem(OSCAR_STATE_TOKEN) || '',
			'Accept-Language': getCurrentLang(),
		},
		data: payload.hasFileData
			? payload.getFormData()
			: payload.requestBodyType === RequestBodyType.json
			? payload.data
			: payload.requestBodyType === RequestBodyType.form
			? Object.keys(payload.data)
					.filter((key) => payload.data[key] != null)
					.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload.data[key])}`)
					.join('&')
			: undefined,
	};
	return axios(axiosOptions).then((res: AxiosResponse<ResBaseBody<TResponseData>>) => {
		const result = res.data;

		if (!result || result.status !== 1) {
			const error = new ServerResError(`${result.message}`, result?.status, result.data);
			const globalError = [
				ERROR_STATUS.NOT_ALLOWED,
				ERROR_STATUS.NOT_FOUND,
				ERROR_STATUS.NOT_LOGIN,
				ERROR_STATUS.SERVICE_TEMPORARILY_UNAVAILABLE,
				ERROR_STATUS.PARAMS_ERROR,
				ERROR_STATUS.UNKNOWN_ERROR,
			];
			if (globalError.includes(result.status)) {
				scoutTrackError({
					name: `${ScoutSpectialRequestError}${result.status}`,
					message: error.message || '-',
					stack: error.stack,
				});
				if (options.showError) {
					Message.error(error.message);
				}
			} else if (options.showError) {
				Message.error(error.message);
			}
			// state token无效
			if (result.status === 100101) {
				try {
					scoutTrackError({
						name: ScoutLoginSessionOutTimeError,
						message: JSON.stringify({
							message: error.message || '-',
							data: result.data || '-',
						}),
						stack: error.stack,
					});
				} catch (error) {
					console.error(error);
				}
				history.push(nextTypeRouteMap[HandlerType.SessionTimeout]);
				// return result.data;
			}
			throw error;
		}
		return result.data;
	});
}
axios.interceptors.response.use(
	(res) => res,
	(err) => {
		console.log(err, 'err');
		if (err && !err.response) {
			err.message = I18N.auto.networkAnomaly;
		}

		if (err && err.code === 'ERR_NETWORK') {
			err.message = I18N.auto.networkAnomaly;
		}

		return Promise.reject(err);
	}
);
