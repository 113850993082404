import React from 'react';
import s from './h5.less';

type Props = {
	children: React.ReactNode | React.ReactNode[];
	bottomNode?: React.ReactNode;
};

export default function PageBoxH5({ children, bottomNode }: Props) {
	return (
		<>
			<div className={`${s.box} ${s.isMobile}`}>{children}</div>
			{bottomNode}
		</>
	);
}
