import { Button as BasicButton } from '@bedrock/components';
import { ButtonProps } from '@bedrock/components/es/Button';
import { useState } from 'react';
import './index.less';

interface Props extends ButtonProps {
	//类似modal的onOk，click事件若返回loading，则自动进入loading态
	onClick?: (e: React.MouseEvent<HTMLElement>) => any;
}

export default function Button(props: Props) {
	const [loading, setLoading] = useState(false);

	function onClick(e: React.MouseEvent<HTMLElement>) {
		if (props.onClick) {
			let res = props.onClick(e);
			if (res instanceof Promise) {
				setLoading(true);
				res.finally(() => {
					setLoading(false);
				});
			}
		}
	}
	return <BasicButton loading={loading} {...props} onClick={onClick}></BasicButton>;
}
