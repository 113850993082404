import classNames from 'classnames';
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import styles from './index.less';

export type GO_BACK_TYPE = 'login' | 'verify' | 'otherVerify';

interface Props {
	style?: any;
	finish: () => void;
	countDownType?: COUNT_DOWN_TYPE;
}
interface CountdownRef {
	start: (expireAt: number) => void;
}

type COUNT_DOWN_TYPE = 'minutes' | 'seconds';

const Countdown: React.FC<Props> = (props: Props, ref) => {
	const { style, finish, countDownType = 'seconds' } = props;
	const [boxBeforeColon, setBoxBeforeColon] = useState(['0', '0']);
	const [boxAfterColon, setBoxAfterColon] = useState(['0', '0']);
	const [second, setSecond] = useState(0);
	const calcRef = useRef<any>(null);
	const timerRef = useRef<any>(null);

	const calc = useCallback(
		(expireAt: number) => {
			const now = new Date().getTime();
			const countdownSecond = Math.floor((expireAt - now) / 1000);
			let m = Math.floor(countdownSecond / 60);
			const s = countdownSecond % 60;
			let beforeRes = String(m).split('');
			let afterRes = String(s).split('');
			if (countDownType === 'seconds') {
				beforeRes = String(m).split('');
				afterRes = String(s).split('');
				if (m < 10) {
					beforeRes.unshift('0');
				}
				if (s < 10) {
					afterRes.unshift('0');
				}
			}
			if (countDownType === 'minutes') {
				const h = Math.floor(m / 60);
				m = m - Math.floor(m / 60) * 60;
				beforeRes = String(h).split('');
				afterRes = String(m).split('');
				if (h < 10) {
					beforeRes.unshift('0');
				}
				if (m < 10) {
					afterRes.unshift('0');
				}
			}
			setBoxBeforeColon(beforeRes);
			setBoxAfterColon(afterRes);
			setSecond(countdownSecond - (countDownType === 'seconds' ? 1 : 60));
		},
		[second, countDownType]
	);

	useEffect(() => {
		calcRef.current = calc;
	}, [calc]);

	useEffect(() => {
		if (second < 0) {
			clearTimeout(timerRef.current);
			setTimeout(() => {
				finish && finish();
			}, 1000);
		}
	}, [second]);

	const timerMs = useMemo(() => {
		return countDownType === 'seconds' ? 1000 : 60000;
	}, [countDownType]);

	// useEffect(() => {
	// 	setExpireAt(originExpireAt);
	// 	const now = new Date().getTime();
	// 	setSecond(Math.ceil((originExpireAt - now) / 1000));
	// }, [originExpireAt]);

	const checkExpireAtValidate = (expireAt: number) => {
		return new Promise((resolve, reject) => {
			if (Number.isNaN(expireAt) || typeof expireAt !== 'number') {
				return reject(new Error('type of params "expireAt" is not number'));
			}
			if (expireAt === 0) {
				return reject(new Error('params "expireAt" can not be 0 '));
			}
			const now = new Date().getTime();
			if (expireAt - now <= 0) {
				return reject(new Error('params "expireAt" can not less than current time'));
			}
			return resolve('start');
		});
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				start: async (expireAt: number) => {
					try {
						await checkExpireAtValidate(expireAt);

						calc(expireAt);
						const fn = () => {
							timerRef.current = setTimeout(() => {
								calcRef.current(expireAt);
								fn();
							}, timerMs);
						};
						fn();
					} catch (err: any) {
						console.log(err, 'err');
					}
				},
			};
		},
		[timerMs]
	);

	return (
		<div className={classNames('flex justify-center', styles['count-down'])} style={{ ...style }}>
			<div className={classNames('flex items-center justify-center', styles.box)}>
				{boxBeforeColon[0]}
			</div>
			<div className={classNames('flex items-center justify-center', styles.box)}>
				{boxBeforeColon[1]}
			</div>
			<div className={classNames('flex flex-col items-center justify-center', styles.semi)}></div>
			<div className={classNames('flex items-center justify-center', styles.box)}>
				{boxAfterColon[0]}
			</div>
			<div className={classNames('flex items-center justify-center', styles.box)}>
				{boxAfterColon[1]}
			</div>
		</div>
	);
};

// @ts-ignore
export default forwardRef<CountdownRef, Props>(Countdown);
