import { apiRemainTimeToNextSendPost } from '@/api';
import { Button } from '@/components';
import { OSCAR_REMAIN_SECOND } from '@/const';
import { Dispatch } from '@/models/store';
import { HandlerType } from '@/types';
import I18N from '@/utils/I18N';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.less';

export type SEND_CODE_TYPE =
	| HandlerType.RecoverPasswordWithCode
	| HandlerType.PhoneSet
	| HandlerType.PhoneSetOtpVerify
	| HandlerType.EmailSetOtpVerify
	| HandlerType.AuthenticatorOscarVerifyPush
	| HandlerType.OscarVerifyBindLinkWait
	| HandlerType.AuthenticatorPhone
	| HandlerType.PreAuthenticatorEmailSend
	| HandlerType.AuthenticatorEmailVerify
	| HandlerType.SSAEVerifyValidationEmail;

interface Props {
	type: SEND_CODE_TYPE;
	title: string;
	account?: string;
	resend?: () => void;
	countDownNum?: number;
}
const COUNT_DOWN_NUM = 60;

export const Resend = (props: Props) => {
	const { type, account = '', title, resend, countDownNum = COUNT_DOWN_NUM } = props;
	const dispatch = useDispatch<Dispatch>();
	const [countdownNum, setCountdownNum] = useState(countDownNum);
	const [countdownBegins, setCountdownBegins] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const countdownNumRef = useRef(countdownNum);
	const timeoutFn = useRef(() => {
		if (countdownNumRef.current === 0) {
			setCountdownBegins(false);
			setCountdownNum(countDownNum);
			sessionStorage.removeItem(OSCAR_REMAIN_SECOND);
			return;
		}
		setCountdownNum(countdownNumRef.current - 1);
		setTimeout(timeoutFn.current, 1000);
	});

	const sendCode = async () => {
		setDisabled(true);
		if (resend) {
			await resend();
			setDisabled(false);
			setCountdownBegins(true);
			timeoutFn.current();
			return;
		}
		await dispatch.oscarVerify.sendCode(
			type === HandlerType.EmailSetOtpVerify
				? { handlerType: type, email: account }
				: { handlerType: type, phone: account }
		);
		setCountdownBegins(true);
		setDisabled(false);
		timeoutFn.current();
	};
	useEffect(() => {
		countdownNumRef.current = countdownNum;
	}, [countdownNum]);

	useEffect(() => {
		const countdownNum = sessionStorage.getItem(OSCAR_REMAIN_SECOND);
		let isBegin = false;
		if (countdownNum && countdownNum !== '0') {
			setCountdownNum(Number(countdownNum));
			isBegin = true;
			setCountdownBegins(isBegin);
			setTimeout(timeoutFn.current, 1000);
		}
		apiRemainTimeToNextSendPost({ handlerType: type, target: account }).then((res) => {
			const remainTime = res.next?.remainTime || 0;
			const newCountdown = Math.ceil(remainTime / 1000);
			if (newCountdown !== 0) {
				sessionStorage.setItem(OSCAR_REMAIN_SECOND, String(newCountdown) || '');
				setCountdownNum(newCountdown || 0);
			}
			if (!isBegin && newCountdown && newCountdown !== 0) {
				setCountdownBegins(true);
				setTimeout(timeoutFn.current, 1000);
			}
			if (newCountdown === 0) {
				sessionStorage.removeItem(OSCAR_REMAIN_SECOND);
			}
		});
	}, []);

	return (
		<div className={classNames('w-full flex', styles.resend)}>
			<span className={styles.text}>
				<span className={styles.prefixTip}>{title}</span>
				{!countdownBegins ? (
					<Button type="text" size="medium" loading={false} onClick={sendCode} disabled={disabled}>
						{I18N.auto.resend}
					</Button>
				) : (
					<div className={styles.desc}>
						{I18N.auto.resend}
						<span style={{ minWidth: 35, display: 'inline-block' }}>({countdownNum})</span>
					</div>
				)}
			</span>
		</div>
	);
};

export default Resend;
