import { Icon, Message, SlidePanel } from '@/components';
import { RootState } from '@/models/store';
import I18N, { getCurrentLang, LangEnum, setLang } from '@/utils/I18N';
import { Globe } from '@bedrock/icons-react';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.less';

interface Props {}

const ChangeLang: React.FC<Props> = (props: Props) => {
	const { isMobile } = useSelector((state: RootState) => state.app);
	let curLocale = getCurrentLang();
	const [langVisible, setLangVisible] = React.useState(false);
	const [currentLang, setCurrentLang] = React.useState(getCurrentLang());
	const languages = [
		{
			label: I18N.common.chinese,
			key: LangEnum['zh-CN'],
		},
		{
			label: I18N.common.english,
			key: LangEnum['en-US'],
		},
		{
			label: I18N.common.japanese,
			key: LangEnum['ja-JP'],
		},
		{
			label: I18N.common.french,
			key: LangEnum['fr-CA'],
		},
	];
	const changeLang = (lang: string | number) => {
		setLang(lang as LangEnum);
		window.location.reload();
	};
	const langName = useMemo(() => {
		return languages.find((item) => item.key === currentLang)!.label;
	}, [currentLang]);

	const isInApp = window.navigator.userAgent.toLocaleLowerCase().includes('oscar');

	if (!isMobile || isInApp) {
		return null;
	}

	return (
		<div className={classNames(styles.changeLangWrap, {})}>
			<div
				className={styles.changeLang}
				onClick={() => {
					setLangVisible(true);
				}}
			>
				<Globe />
				<span className={styles.name}>{langName}</span>
			</div>

			<SlidePanel
				title={I18N.auto.langSetting}
				height={295}
				visible={langVisible}
				finishBtnStyle={{ color: currentLang !== curLocale ? '#2675FC' : '#BFBFBF' }}
				onClose={() => {
					setCurrentLang(curLocale);
					setLangVisible(false);
				}}
				onFinish={() => {
					if (currentLang !== curLocale) {
						setLangVisible(false);
						Message.loading(I18N.auto.languageSwitching);
						setTimeout(() => {
							changeLang(currentLang);
						}, 500);
					}
				}}
			>
				<div className={styles.language}>
					{languages.map((item) => {
						return (
							<div key={item.key} className={styles['lang-item-wrap']}>
								<span
									className={styles['lang-item']}
									onClick={() => {
										setCurrentLang(item.key);
									}}
								>
									{item.label}
									{currentLang === item.key && <Icon name="icon-check" />}
								</span>
							</div>
						);
					})}
				</div>
			</SlidePanel>
		</div>
	);
};

export default ChangeLang;
